import { UPLOAD_MAX_SIZE } from "shared/constants/file";

export default {
    dashboard: "Metadata Monitor",
    logo: "Metadata Monitor",
    error_boundary: {
        message: "おっと！何かが間違っていたようだ。",
        submessage: "取り組んでいる",
        back: "バックホーム",
    },
    security: {
        login: {
            title: "ログイン",
            username: "メールアドレス",
            password: "パスワード",
            noAccount: "アカウントをお持ちでないですか？",
            create: "サインアップ",
            forgot: "パスワードを忘れましたか？",
        },
        request: {
            title: "リクエスト",
            username: "メールアドレス",
            message:
                "メールが送信されました。送信されたリンクをクリックしてパスワードをリセットして下さい。\n\nもしメールが届かない場合は、迷惑メールフォルダをご確認いただき、その上で再度やり直して下さい。",
            button: "パスワードのリセット",
            login: "ログインに戻る",
        },
        reset: {
            title: "パスワードの変更",
            password: "新しいパスワード",
            success: "パスワードのリセットが完了しました",
            error: "パスワードをリセットできない",
            button: "パスワードの変更",
            invalid_password: "無効なパスワード",
            login: "ログインに戻る",
        },
        register: {
            title: "アカウントを登録",
            subtitle: {
                trial: "当社のデータを試してみたいですか？今日から2週間の無料トライアルを開始しましょう。",
                client: "クライアントとして、すでにメタデータモニターにアクセスできます。あなたのアカウントは、適切なアクセスを提供するために検証されます。",
            },
            is_client: "すでにクライアントですか？",
            email: "メールアドレス",
            firstname: "ファーストネーム",
            lastname: "ラストネーム",
            password: "パスワード",
            account_type: {
                title: "アカウントタイプ",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            role: {
                title: "役割",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "説明する",
            language: "言語",
            label: "レーベル",
            company: "会社",
            success:
                "アカウントが正常に作成されました。登録を完了し、メタデータモニターにアクセスするためにメールアドレスを確認してください。",
            error: "アカウントを作成できない",
            button: "はじめる",
            allreadyAccount: "すでにアカウントをお持ちですか？",
            agreeBefore: "登録することで、Music-Storyに同意するものとします。",
            termsOfService: "利用規約",
            agreeAnd: "そして",
            privacyPolicy: "プライバシーポリシー",
            invalid_password: "無効なパスワード",
        },
        access: {
            denied: "無効な認証情報です。メールアドレスでログインしてみてください。",
            expired: "アカウント失効",
            lock: "アカウントロック",
        },
        validate: {
            success: "登録が正常に完了しました。これでログインできます。",
            error: "アカウントを確認できません",
        },
    },
    user: {
        breadcrumb: "ユーザー",
        manage: {
            breadcrumb: "マネージメント",
            user: {
                head: "ユーザー",
                empty: "結果なし",
                id: "Id",
                username: "名前",
                is_client: "すでにクライアントですか？",
                firstname: "ファーストネーム",
                lastname: "ラストネーム",
                email: "メールアドレス",
                user_roles: "権利",
                label: "関連レーベル",
                api_limit_per_day: "1日あたりのAPIリクエスト数",
                api_limit_per_second: "1秒あたりのAPIリクエスト数制限",
                image_server: "画像サーバー",
                author: "作家",
                account_type: {
                    title: "アカウントタイプ",
                    music_service: "Music service",
                    cmo: "CMO",
                    label_distributor: "Label / Distributor",
                    artist_manager: "Artist / Manager",
                },
                role: {
                    title: "役割",
                    music_editor: "Music editor",
                    marketing: "Marketing",
                    catalog_management: "Catalog Management",
                    product_management: "Product Management",
                    tech: "Tech",
                    executive: "Executive",
                    other: "Other",
                    promotion: "Promotion",
                    business_development: "Business development",
                    a_and_r: "A&R",
                    supply_chain: "Supply chain",
                    analytics: "Analytics",
                },
                describe: "説明する",
                language: "言語",
                company: "会社",
                last_login: "最終接続",
                expires_at: "有効期限",
                token: "トークン",
                expires: "期限切れ",
                action: "実行",
                search: "検索...",
            },
            create: {
                title: "ユーザー作成",
                success: "ユーザー作成済",
                error: (message) =>
                    `ユーザー作成中にエラーが発生しました: ${message}`,
            },
            update: {
                title: "ユーザー更新",
                success: "ユーザーが更新されました",
                error: (message) =>
                    `ユーザー更新中にエラーが発生しました: ${message}`,
            },
            change_password: {
                title: "パスワード変更",
                password: "パスワード",
                comfirm: "パスワード確認",
                success: "パスワード更新",
                error: (message) =>
                    `パスワード更新中にエラーが発生しました: ${message}`,
            },
            promote: {
                title: "ユーザーを昇格",
                alert: {
                    title: "間違いないですか？",
                    text: "このユーザーを昇格しますか？",
                },
                success: "ユーザー昇格済",
                error: (message) =>
                    `ユーザー昇格中にエラーが発生しました: ${message}`,
            },
            unpromote: {
                title: "ユーザー格下げ",
                alert: {
                    title: "間違いないですか？",
                    text: "このユーザーを格下げしますか？",
                },
                success: "ユーザー格下げ済",
                error: (message) =>
                    `ユーザー格下げ中にエラーが発生しました: ${message}`,
            },
            lock: {
                title: "ユーザーロック",
                alert: {
                    title: "間違いないですか？",
                    text: "このユーザーをロックしていいですか？",
                },
                success: "ユーザーがロックされました",
                error: (message) =>
                    `ユーザーをロック中にエラーが発生しました: ${message}`,
            },
            unlock: {
                title: "ユーザーアンロック",
                alert: {
                    title: "間違いないですか？",
                    text: "このユーザーをアンロックしていいですか？",
                },
                success: "ユーザーがアンロックされました",
                error: (message) =>
                    `ユーザーをアンロック中にエラーが発生しました: ${message}`,
            },
            generate_token: {
                success: "トークン作成",
                error: (message) =>
                    `トークンを作成中にエラーが発生しました: ${message}`,
            },
        },
        role: {
            breadcrumb: "役割",
            manage: {
                breadcrumb: "役割変更",
                role: {
                    head: "役割変更",
                    empty: "結果なし",
                    id: "Id",
                    libelle: "レーベル",
                    rights: "権利",
                    action: "実行",
                    search: "検索...",
                },
                create: {
                    title: "役割を作成",
                    success: "役割を作成しました",
                    error: (message) =>
                        `役割を作成中にエラーが発生しました: ${message}`,
                },
                update: {
                    title: "役割を更新",
                    success: "役割を更新しました",
                    error: (message) =>
                        `役割を更新中にエラーが発生しました: ${message}`,
                },
                delete: {
                    title: "役割を削除",
                    alert: {
                        title: "間違いないですか？",
                        text: "この役割を削除してもいいですか？",
                    },
                    success: "役割を削除しました",
                    error: (message) =>
                        `役割を削除中にエラーが発生しました: ${message}`,
                },
            },
        },
        right: {
            breadcrumb: "役割",
            manage: {
                breadcrumb: "役割変更",
                right: {
                    head: "役割変更",
                    empty: "結果なし",
                    id: "Id",
                    reference: "参照",
                    libelle: "レーベル",
                    action: "実行",
                    search: "検索...",
                },
                create: {
                    title: "クリエイトライト",
                    success: "ライトクリエイト",
                    error: (message) =>
                        `この権利を作成する際にエラーが発生する: ${message}`,
                },
                update: {
                    title: "アップデート権",
                    success: "右更新",
                    error: (message) =>
                        `この権利を更新する際にエラーが発生する: ${message}`,
                },
                delete: {
                    title: "右を削除する",
                    alert: {
                        title: "間違いないですか？",
                        text: "この権利を削除したいのですか？",
                    },
                    success: "右の削除",
                    error: (message) =>
                        `この権利を削除する際にエラーになる: ${message}`,
                },
            },
        },
    },
    author: {
        breadcrumb: "作者",
        manage: {
            breadcrumb: "マネージメント",
            author: {
                head: "作者",
                empty: "結果なし",
                id: "Id",
                firstname: "名",
                lastname: "姓",
                signature: "署名",
                action: "実行",
                search: "検索...",
            },
            create: {
                title: "作者の作成",
                success: "作者が作成されました",
                error: (message) =>
                    `作者を作成中にエラーが発生しました: ${message}`,
            },
            update: {
                title: "作者変更",
                success: "作者が変更されました",
                error: (message) =>
                    `作者を変更にエラーが発生しました: ${message}`,
            },
            delete: {
                title: "作者削除",
                alert: {
                    title: "間違いないですか？",
                    text: "この作者を削除してもいいですか？",
                },
                success: "作者が削除されました",
                error: (message) =>
                    `作者を削除中にエラーが発生しました: ${message}`,
            },
        },
    },
    artist: {
        search: {
            breadcrumb: "アーティストのリスト",
            placeholder:
                "ID / ISNI / 氏名 / 本名 / 翻訳名 / 通称名 / ニックネーム",
            empty: "結果なし",
            init: "検索してください",
            add: "アーティストを追加",
            id: "Id",
            name: "名前",
            complement: "補足",
            genre: "ジャンル",
            actions: "実行",
            mainGenre: "メインジャンル",
            principalGenre: "主ジャンル",
            secondaryGenre: "サブジャンル",
            country: "国",
            type: "タイプ",
            gender: "性別",
            role: "役割",
            reset: "検索リセット",
            error: (message) =>
                `アーティストを検索中にエラーが発生しました: ${message}`,
            majorGenreGroup: "メインジャンル",
            otherGenreGroup: "他のジャンル",
            filters: {
                bio_en: {
                    title: "英語バイオグラフィー",
                    yes: "英語バイオグラフィー付き",
                    no: "W英語バイオグラフィー無し",
                },
                bio_fr: {
                    title: "フランス語バイオグラフィー",
                    yes: "フランス語バイオグラフィー付き",
                    no: "フランス語バイオグラフィー無し",
                },
                bio_de: {
                    title: "ドイツ語バイオグラフィー",
                    yes: "ドイツ語バイオグラフィー付き",
                    no: "ドイツ語バイオグラフィー無し",
                },
                bio_pt: {
                    title: "ポルトガル語バイオグラフィー",
                    yes: "ポルトガル語バイオグラフィー付き",
                    no: "ポルトガル語バイオグラフィー無し",
                },
                bio_es: {
                    title: "スペイン語伝記",
                    yes: "スペイン語伝記付き",
                    no: "スペイン語伝記なし",
                },
                curation: {
                    title: "キューレーション",
                    yes: "キューレーション画像付き",
                    no: "キューレーション画像無し",
                },
                getty: {
                    title: "ゲッティ",
                    yes: "ゲッティ画像付き",
                    no: "ゲッティ画像無し",
                },
                label: {
                    title: "レーベル",
                    yes: "レーベル画像付き",
                    no: "レーベル画像無し",
                },
                editorialized: {
                    title: "エディトリアル化",
                    yes: "編集部調べ",
                    no: "編集されたものではありません",
                },
            },
        },
        view: {
            breadcrumb: "アーティストビジュアライゼーション",
            id: "Music-Story ID",
            complementary: "補足説明",
            type: "タイプ",
            mainGenre: "メインジャンル",
            country: "国",
            profile: "プロフィール",
            univers: "ユニバース",
            credits: "クレジット",
            charts: "トップソング",
            disco: "ディスコグラフィー",
            images: "画像",
            biography: "バイオグラフィー",
            popularity: "人気",
            noData: {
                univers: "このアーティストのユニバースはありません",
                images: "このアーティストの画像はありません",
                disco: "このアーティストのディスコグラフィーはありません",
                credits: "このアーティストのクレジットはありません",
                charts: "このアーティストのトップ曲はありません",
                biography: "このアーティストのバイオグラフィーはありません",
                popularity: "このアーティストの人気はありません",
            },
            profileInformation: {
                head: "追加情報",
                role: "役割",
                gender: "性別",
                Personne: {
                    born: "生年月日",
                    birth_country: "出生国",
                    birth_area: "出生地",
                    birth_city: "出生市",
                    died: "死亡日",
                    death_country: "死亡国",
                    death_area: "死亡地",
                    death_city: "死亡市",
                },
                Groupe: {
                    born: "結成日",
                    birth_country: "結成国",
                    birth_area: "結成地",
                    birth_city: "結成市",
                    died: "解散日",
                    death_country: "解散国",
                    death_area: "解散地",
                    death_city: "解散市",
                },
                famousFor: "概要",
                awards: "受賞歴",
            },
            profileNames: {
                head: "名称",
                realname: "本名",
                alias: "エイリアス",
                names: "代替言語",
            },
            profileMembers: {
                head: "メンバー",
                current: "現在のメンバー",
                old: "旧メンバー",
            },
            profileGroup: {
                head: "バンド",
                current: "現在のバンド",
                old: "旧バンド",
            },
            profileExternalLink: {
                head: "外部リンク",
                isni: "ISNI",
                site: "ウェブサイト",
            },
            profilePartnerLink: {
                head: "外部リンク",
            },
            universGenres: {
                head: "ジャンル",
                main: "メイン",
                principal: "主",
                secondary: "サブ",
                influence: "影響",
            },
            universRelated: {
                head: "関連アーティスト",
                related: "関連",
                successor: "後継",
                influence: "影響",
            },
            universLanguages: {
                head: "歌われる言語",
                principal: "主な言語",
                secondary: "その他の言語",
            },
            creditsCoPerformer: {
                head: "共演者",
            },
            creditsContribution: {
                head: "コントリビューション",
                description:
                    "現在のアーティストがコラボレーションしたことのあるアーティスト（例：他のアーティストの楽曲に参加、他のアーティストの作曲、他のアーティストの楽器演奏...）。",
            },
            creditsCollaboration: {
                head: "コラボレーション",
                description:
                    "現在のアーティストとコラボレーションしたアーティスト（作曲家、主な出演者、...など）",
            },
            chartsRecordingCharts: {
                mainCharts: "公式チャート",
                allCharts: "全てのチャート",
                cover: "カバー",
                title: "タイトル",
                star: "スコア",
                peak_rank: "順位",
                chart: "チャート",
                country: "国名",
                entry_date: "エントリー日",
                trend: "トレンド",
                year: "年",
                multi_charts: "マルチチャート",
                multi_country: "多国間",
                resetCharts: "フィルター/ソートのリセット",
                resetEditorsPick: "振り分けをリセットする",
                hit: {
                    title: "ヒット",
                    description: "アーティストの人気曲を専門家がセレクト",
                },
                editorial_pick: {
                    title: "エディターズピック",
                    description: "音楽編集者が選ぶ注目曲",
                },
                empty: "結果なし",
            },
            discoAlbums: {
                head: "アルバム",
                id: "Id",
                cover: "カバーアート",
                title: "タイトル",
                type: {
                    head: "タイプ",
                    live: "ライブ",
                    remix: "リミックス",
                    compilation: "コンピレーション",
                    karaoke: "カラオケ",
                    ring: "リング",
                    other: "その他",
                    original: "オリジナル",
                },
                format: {
                    head: "フォーマット",
                    album: "アルバム",
                    ep: "ミニアルバム(EP)",
                    single: "シングル",
                    mixtape: "ミックステープ",
                    mcd: "マキシ",
                },
                year: "年",
                genre: "名前",
                release_date: "リリース日",
                reference: "レート",
                has_review: "レビュー",
                has_credits: "クレジット",
                has_chart: "チャート",
                complementary: "関連コンテンツ",
                empty: "結果なし",
                reset: "フィルター/ソートのリセット",
            },
            imagesGallery: {
                head: "ギャラリー",
                tags: "タグで絞り込む",
                empty: "ギャラリーの中に画像がありません",
                detail: {
                    head: "ディテール",
                    show: "原画を表示する",
                    source: "ソース",
                    copyright: "著作権",
                    tags: "タグ",
                    metas: "メタ",
                },
            },
            biographyGallery: {
                writer: "作家",
                updated_at: "更新日",
                words: "単語",
                signs: "文字",
                biography: "伝記",
                empty: "バイオグラフィーなし",
                licence: {
                    title: "提供：CDジャーナル",
                    subtitle:
                        "このコンテンツは、CDジャーナルから配信される特定のライセンスなしに使用することはできません。",
                },
            },
            popularityOverview: {
                head: "概要",
                global: {
                    title: "グローバル",
                    recordings: "チャートインしたタイトル数：",
                    description:
                        "アーティストの人気スコアは、チャートデータに基づいています。アーティストのタイトル人気の結果であり、アーティストのランク（人気アーティストランキング内）と人気率（%、最高人気スコアとの相対）の2つの形式で表現されます。",
                },
                gold: {
                    title: "過去",
                    recordings: "過去チャート内のタイトル数：",
                    description:
                        "アーティストのカタログ人気は、18ヶ月以上前のチャートでの存在に基づいています。カタログ人気は、現在Billboard Hot 100、UK Official Singles Chart & Offizielle Deutsche Chartsのデータのみを使用しています。",
                },
                current: {
                    title: "現在",
                    recordings: "現在のチャートでのタイトル：",
                    description:
                        "アーティストの現在の人気は、過去18ヶ月間のチャートでの存在感に基づいています。",
                },
                type: {
                    label: "タイプ：",
                    gold: "オールディーズ",
                    current: "現在",
                },
                peak: "ピーク：",
            },
            popularityHistogram: {
                head: "年別の人気度",
                tabs: {
                    gold: {
                        title: "過去",
                        description:
                            "アーティストのカタログ人気は、18ヶ月以上前のチャートでの存在に基づいています。カタログ人気は、現在Billboard Hot 100、UK Official Singles Chart & Offizielle Deutsche Chartsのデータのみを使用しています。",
                        header: "カタログ人気はUS、UK、DEの公式チャートデータのみに基づいており、より多くの国が統合されている。",
                    },
                    current: {
                        title: "現在",
                        description:
                            "アーティストの現在の人気は、過去18ヶ月間のチャートでの存在感に基づいています。",
                    },
                },
                gold: "ゴールドの人気",
                current: "現在の人気",
                popover: {
                    noData: "データなし",
                    popularity: "アーティストの人気者：",
                    mostPopularTitle: "最も人気のあるタイトル：",
                    bestRank: "ベストランクです：",
                    bestCountry: "最高の国です：",
                    charts: (n) => `${n}枚のチャートに基づいています。`,
                },
            },
            popularityMap: {
                head: "国別人気度",
                tabs: {
                    gold: {
                        title: "過去",
                        description:
                            "アーティストのカタログ人気は、18ヶ月以上前のチャートでの存在に基づいています。カタログ人気は、現在Billboard Hot 100、UK Official Singles Chart & Offizielle Deutsche Chartsのデータのみを使用しています。",
                    },
                    current: {
                        title: "現在",
                        description:
                            "アーティストの現在の人気は、過去18ヶ月間のチャートでの存在感に基づいています。",
                    },
                },
                popover: {
                    noData: "データなし",
                    popularity: "アーティストの人気者：",
                    mostPopularTitle: "最も人気のあるタイトルです：",
                    bestRank: "ベストランクです：",
                    bestMonth: "ベストランクの月：",
                    weeksInCharts: "チャートで見る週数：",
                    charts: (n) => `${n}枚のチャートに基づいています。`,
                },
            },
        },
        create: {
            breadcrumb: "作成",
            placeholder:
                "ID / ISNI / 氏名 / 本名 / 翻訳名 / 通称名 / ニックネーム",
            empty: "結果なし",
            init: "検索してください",
            source: "ソース",
            name: "名前",
            complement: "補足",
            type: "タイプ",
            gender: "性別",
            country: "国",
            real_name: "本名",
            alias: "エイリアス",
            member: "メンバー",
            band: "バンド",
            site: "ウェブサイト",
            social: "SNS",
            actions: "実行",
            create: {
                title: "クリエイター",
                alert: {
                    title: "間違いないですか？",
                    text: "このアーティストを作りたいとは？",
                },
                success: "アーティストが作成",
                error: (message) =>
                    `このアーティストを作成中にエラーが発生した: ${message}`,
            },
        },
        management: {
            breadcrumb: "アーティストを編集",
            profile: "プロフィール",
            editorial: "エディトリアル",
            biography: "バイオグラフィー",
            discography: "ディスコグラフィー",
            curation: "キューレーション",
            getty: "ゲッティ",
            labelPicture: "レーベル画像",
            matching: "マッチング",
            charts: "チャート",
            tracking: "トラッキング",
            action: {
                complete: {
                    message: "アーティスト・イン・プログレスの創作",
                    button: "作成完了",
                    alert: {
                        title: "間違いないですか？",
                        text: "このアーティストのクリエイションを完成させるために？",
                    },
                    success: "クリエーション完了",
                    error: (message) =>
                        `このアーティストを作成中にエラーが発生した: ${message}`,
                },
            },
            profileInformation: {
                head: "メイン情報",
                type: "タイプ",
                name: "名前",
                complement: "補足",
                realname: "本名",
                gender: "性別",
                country: "国",
                isni: "ISNI",
                isniSearch: {
                    tooltip: "ISNIを検索",
                    head: "ISNIを検索",
                    placeholder: "名前またはISNIで検索",
                    error: (message) =>
                        `検索中にエラーが発生しました: ${message}`,
                    isni: "ISNI",
                    name: "名前",
                    select: "ISNIを選択",
                    empty: "結果なし",
                },
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "メイン情報を更新",
                    success: "情報を更新しました",
                    error: (message) =>
                        `情報を更新中にエラーが発生しました: ${message}`,
                },
            },
            names: {
                head: "代替言語",
                empty: "言語無し",
                name: "名前",
                language: "言語",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "代替言語を更新",
                    success: "代替言語を更新しました",
                    error: (message) =>
                        `代替言語を更新中にエラーが発生しました: ${message}`,
                },
            },
            alias: {
                head: "エイリアス",
                empty: "エイリアス無し",
                alias: "エイリアス",
                type: "タイプ",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "エイリアスを更新",
                    success: "エイリアスを更新しました",
                    error: (message) =>
                        `エイリアスを更新中にエラーが発生しました: ${message}`,
                },
            },
            member: {
                head: "メンバー",
                empty: "メンバー無し",
                artist: "名前",
                start_date: "開始日",
                end_date: "終了日",
                role: "役割",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "メンバーを更新",
                    success: "メンバーを更新しました",
                    error: (message) =>
                        `メンバーを更新中にエラーが発生しました: ${message}`,
                },
            },
            group: {
                head: "バンド",
                empty: "バンドがありません",
                artist: "名前",
                start_date: "開始日",
                end_date: "終了日",
                role: "役割",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "バンドを更新",
                    success: "更新が更新されました",
                    error: (message) =>
                        `バンドを更新中にエラーが発生しました: ${message}`,
                },
            },
            genre: {
                head: "ジャンル",
                empty: "ジャンルがありません",
                genre: "名前",
                type: "タイプ",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "ジャンルを更新",
                    success: "ジャンルが更新されました",
                    error: (message) =>
                        `ジャンルを更新中にエラーが発生しました: ${message}`,
                },
            },
            role: {
                head: "役割",
                empty: "役割がありません",
                role: "役割",
                main: "メイン",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "役割更新",
                    success: "役割が更新されました",
                    error: (message) =>
                        `役割を更新中にエラーが発生しました: ${message}`,
                },
            },
            social: {
                head: "SNS",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "SNS更新",
                    success: "SNSが更新されました",
                    error: (message) =>
                        `SNSを更新中にエラーが発生しました: ${message}`,
                },
                duplicates: {
                    tooltip: "同じSNSのリンクを持つ他のアーティストがいます",
                    head: "SNS重複",
                    body: "次のアーティストのSNSリンクは同じです",
                },
            },
            site: {
                head: "ウェブサイト",
                empty: "ウェブサイトがありません",
                url: "リンク",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "ウェブサイト更新",
                    success: "ウェブサイトが更新されました",
                    error: (message) =>
                        `ウェブサイトを更新中にエラーが発生しました: ${message}`,
                },
            },
            editorialInformation: {
                head: "メイン情報",
                Personne: {
                    born: "生年月日",
                    birth_country: "出生国",
                    birth_area: "出生地",
                    birth_city: "出生市",
                    died: "死亡日",
                    death_country: "死亡国",
                    death_area: "死亡地",
                    death_city: "死亡市",
                },
                Groupe: {
                    born: "結成日",
                    debut_date: "由来",
                    birth_country: "結成国",
                    birth_area: "結成地",
                    birth_city: "結成市",
                    activity_period: "営業年数",
                    activity_period_start: "開始日",
                    activity_period_end: "終了日",
                    died: "解散日",
                    death_country: "解散国",
                    death_area: "解散地",
                    death_city: "解散市",
                },
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "メイン情報を更新",
                    success: "メイン情報を更新しました",
                    error: (message) =>
                        `メイン情報を更新中にエラーが発生しました: ${message}`,
                },
            },
            highlights: {
                head: "ハイライト",
                famous_for: "概要",
                awards: "受賞歴",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "ハイライト更新",
                    success: "ハイライトを更新しました",
                    error: (message) =>
                        `ハイライトを更新中にエラーが発生しました: ${message}`,
                },
            },
            collaboration: {
                head: "コラボレーション",
                empty: "コラボレーションがありません",
                artist: "名前",
                start_date: "開始日",
                end_date: "終了日",
                role: "役割",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "コラボレーション更新",
                    success: "コラボレーションを更新しました",
                    error: (message) =>
                        `コラボレーションを更新中にエラーが発生しました: ${message}`,
                },
            },
            contribution: {
                head: "コントリビューション",
                empty: "コントリビューションがありません",
                artist: "名前",
                start_date: "開始日",
                end_date: "終了日",
                role: "役割",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "コントリビューション更新",
                    success: "コントリビューションを更新しました",
                    error: (message) =>
                        `コントリビューションを更新中にエラーが発生しました: ${message}`,
                },
            },
            related: {
                head: "関連アーティスト",
                empty: "関連アーティストがありません",
                artist: "名前",
                type: "タイプ",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "関連アーティスト更新",
                    success: "関連アーティストを更新しました",
                    error: (message) =>
                        `関連アーティストを更新中にエラーが発生しました: ${message}`,
                },
            },
            bio: {
                head: {
                    FR: "Music Story FR",
                    EN: "Music Story EN",
                    DE: "Music Story DE",
                    PT: "Music Story PT",
                    JA: "Music Story JA",
                    ES: "Music Story ES",
                },
                is_reference: "参考略歴",
                to_translate: () => (
                    <>
                        進行中のプロセ
                        <br />
                        この経歴は、対応する参照経歴から自動的に翻訳され、更新されます。何もする必要はありません。
                    </>
                ),
                author: "作家",
                archive: "アーカイブ",
                cd_journal: "CDジャーナル",
                reset: {
                    title: "バイオグラフィーをリセット",
                },
                update: {
                    title: "バイオグラフィー更新",
                    success: "バイオグラフィーを更新しました",
                    alert: {
                        title: "間違いないですか？",
                        text: "翻訳済みの伝記を修正しようとしていますが、修正後は翻訳されなくなります。",
                    },
                    error: (message) =>
                        `バイオグラフィーを更新中にエラーが発生しました: ${message}`,
                },
                translation: {
                    placeholder: "言語を選択してください",
                    alert: {
                        title: "間違いないですか？",
                        text: "この経歴を変えたいのか？",
                    },
                },
            },
            wikipedia: {
                head: "ウィキペディア",
                empty: "ウィキペディアがありません",
                url: "リンク",
                reset: {
                    title: "フォームをリセット",
                },
                update: {
                    title: "ウィキペディア更新",
                    success: "ウィキペディアを更新しました",
                    error: (message) =>
                        `ウィキペディアを更新中にエラーが発生しました: ${message}`,
                },
                names: {
                    head: 'ウィキペディアの名前を代替言語の中に追加する"',
                    lang: "言語",
                    name: "名前",
                },
            },
            disco: {
                albums: {
                    head: "アルバム",
                    search: "ID / タイトル",
                    empty: "結果なし",
                    id: "ID",
                    title: "タイトル",
                    type: {
                        head: "タイプ",
                        live: "ライブ",
                        remix: "リミックス",
                        compilation: "コンピレーション",
                        karaoke: "カラオケ",
                        ring: "リング",
                        other: "その他",
                        original: "オリジナル",
                    },
                    format: {
                        head: "For.",
                        title: "フォーマット",
                        album: "アルバム",
                        ep: "ミニアルバム(EP)",
                        single: "シングル",
                        mixtape: "ミックステープ",
                        mcd: "マキシ",
                    },
                    genre: "ジャンル",
                    release_date: {
                        head: "日付",
                        title: "リリース日",
                    },
                    cover: {
                        head: "Cov.",
                        title: "カバーアート",
                    },
                },
                releases: {
                    head: "リリース",
                    search: "ID / バーコード",
                    empty: "結果なし",
                    id: "ID",
                    barcode: {
                        head: "バーコード",
                        title: "バーコード",
                    },
                    commentary: "コメンタリー",
                    support: "サポート",
                    release_date: {
                        head: "日付",
                        title: "リリース日",
                    },
                },
                sandbox: {
                    head: "サンドボックス",
                    search: {
                        input: "ID / タイトル / バーコード",
                        title: "全てのディスコグラフィーを検索",
                        result: (query) => `の検索結果"${query}"`,
                        reset: "検索をリセット",
                    },
                    empty: "結果なし",
                    partner: "パートナー",
                    id: "Id",
                    barcode: "バーコード",
                    title: "タイトル",
                    nb_track: "トラックナンバー",
                    actions: "実行",
                    view_product: "プロダクトを見る",
                    compare_tracklists: "トラックリストを比較",
                    view_matchings: "マッチを見る",
                    popover: {
                        id: "ID:",
                        title: "タイル:",
                        artist: "アーティスト:",
                        barcode: "バーコード:",
                        label: "レーベル:",
                        release_date: "リリース日:",
                        cover_art_error: "カバーアートのロードに失敗しました",
                    },
                    matchings: {
                        head: "マッチング",
                        body: "このプロダクトは以下のディスコグラフィーと一致しています:",
                        artists: "アーティスト",
                        album: "アルバム",
                        release: "リリース",
                        close: "閉じる",
                    },
                    tracklistComparison: {
                        head: "トラックリスト比較",
                        tracks: {
                            head: "MusicStoryトラックリスト",
                            empty: "結果なし",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "ISRC",
                            title: "タイトル",
                            subtitle: "サブタイトル",
                            duration: "長さ",
                            hit: "ヒット",
                            discovery: {
                                head: "EP",
                                title: "エディターズピック",
                            },
                        },
                        partnerTracks: {
                            head: "パートナートラックリスト",
                            empty: "結果なし",
                            no_cd: "D",
                            no_piste: "T",
                            isrc: "ISRC",
                            title: "タイトル",
                            duration: "長さ",
                        },
                        replace: {
                            title: "MusicStoryのトラックリストに取り換える",
                            alert: {
                                title: "間違いないですか？",
                                text: "このリリースのトラックリストを取り替えますか？",
                            },
                            success: "トラックリストが取り替えられました",
                            error: (message) =>
                                `トラックリストを取替中にエラーが発生しました: ${message}`,
                            warningIsrc: {
                                title: "間違いないですか？",
                                text: "このリリースのトラックリストを入れ替えようとしたところ、次のような警告が出た。: 同じISRCを持つレコードが見つかりました",
                            },
                        },
                    },
                    copyProduct: {
                        title: "プロダクトを追加またはマッチする",
                        head: "プロダクトを追加またはマッチする",
                        copyAsAlbum: {
                            title: "ニューアルバムとして追加する",
                            created:
                                "プロダクトはニューアルバムとして追加されています",
                            error: (message) =>
                                `アルバムを追加またはマッチ中にエラーが発生しました: ${message}`,
                            warningBarcode: {
                                title: "間違いないですか？",
                                text: (message) =>
                                    `このアルバムを追加しようとすると、次のような警告が表示されました。: ${message}`,
                            },
                            warningIsrc: {
                                title: "間違いないですか？",
                                text: "このアルバムを追加しようとすると、次のような警告が表示されました。: 同じISRCを持つレコードが見つかりました",
                            },
                        },
                        copyAsRelease: {
                            title: "現在のアルバムのニューリリースとして追加",
                            created:
                                "プロダクトはニューリリースとして追加されています",
                            matched:
                                "プロダクトは既存のリリースとマッチされています",
                            error: (message) =>
                                `リリースとして追加またはマッチ中にエラーが発生しました: ${message}`,
                            warningBarcode: {
                                title: "間違いないですか？",
                                text: (message) =>
                                    `このリリースを追加しようとすると、次のような警告が表示されました。: ${message}`,
                            },
                            warningIsrc: {
                                title: "間違いないですか？",
                                text: "このリリースを追加しようとすると、次のような警告が表示されました。: 同じISRCを持つレコードが見つかりました",
                            },
                        },
                    },
                    pagination: ({ from, to, size }) => `${size} プロダクト`,
                    filters: {
                        partners: "パートナー",
                        status: "ステータス",
                    },
                    status: {
                        MATCHED_IN_CURRENT_DISCOGRAPHY:
                            "現在のディスコグラフィーにマッチされています",
                        MATCHED_IN_OTHER_DISCOGRAPHY:
                            "他のディスコグラフィーにマッチされています",
                        NOT_MATCHED_COMPLETE: "マッチ無し、完了",
                        NOT_MATCHED_INCOMPLETE: "マッチ無し、未完了",
                    },
                },
                tracks: {
                    head: "トラック",
                    search: "ID / ISRC / タイトル",
                    empty: "結果なし",
                    no_cd: {
                        head: "D",
                        title: "ディスク番号",
                    },
                    no_piste: {
                        head: "T",
                        title: "トラック番号",
                    },
                    id: "ID",
                    isrc: "ISRC",
                    title: "タイトル",
                    subtitle: {
                        head: "サブタイトル",
                        title: "サブタイトル",
                    },
                    duration: {
                        head: "長さ",
                        title: "長さ",
                    },
                },
                matching: {
                    partner: "アフィリエイト",
                    id: "ID",
                    actions: "実行",
                    view: "トラックを見る",
                    delete: "削除",
                },
                objects: {
                    album: {
                        tabTitle: "アルバム",
                        basic: {
                            head: "基本情報",
                            artist: "アーティスト",
                            title: "タイトル",
                            subtitle: "サブタイトル",
                            type: "タイプ",
                            format: "フォーマット",
                            label: "レーベル",
                            publisher: "出版社",
                            release_date: "リリース日",
                            genre: "ジャンル",
                            reference: "レート",
                            typeOptions: {
                                live: "ライブ",
                                remix: "リミックス",
                                compilation: "コンピレーション",
                                karaoke: "カラオケ",
                                ring: "リング",
                                other: "その他",
                                original: "オリジナル",
                            },
                            formatOptions: {
                                album: "アルバム",
                                ep: "ミニアルバム(EP)",
                                single: "シングル",
                                mixtape: "ミックステープ",
                                mcd: "マキシ",
                            },
                            reset: {
                                title: "フォームをリセット",
                            },
                            create: {
                                title: "アルバムを作成",
                                success: "アルバムが作成されました",
                                error: (message) =>
                                    `アルバム作成中にエラーが発生しました: ${message}`,
                            },
                            update: {
                                title: "アルバムを更新",
                                success: "アルバムが更新されました",
                                error: (message) =>
                                    `アルバム更新中にエラーが発生しました: ${message}`,
                            },
                            delete: {
                                title: "アルバムを削除",
                                hasReleases:
                                    "アルバムにはまだ1つまたは複数のエディションがあります",
                                hasReview: "アルバムはまだレビュー中です",
                                alert: {
                                    title: "間違いないですか？",
                                    text: "このアルバムを削除していいですか？",
                                },
                                warning: {
                                    title: "間違いないですか？",
                                    text: (messages) =>
                                        `このアルバムを削除する際の警告: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "アルバムを削除",
                                error: (message) =>
                                    `アルバムを削除中にエラーが発生しました: ${message}`,
                            },
                            transfer: {
                                title: "アルバムを転送",
                                success: ({ artist, album }) =>
                                    `アルバムを"${album}" アーティストに "${artist}" 正常に転送する`,
                                error: (message) =>
                                    `アルバム転送中にエラーが発生しました: ${message}`,
                            },
                        },
                        artist: {
                            head: "アーティスト",
                            main: "メイン",
                            principal: "主",
                            reset: {
                                title: "フォームをリセット",
                            },
                            update: {
                                title: "アーティストを更新",
                                success: "アーティストを更新しました",
                                error: (message) =>
                                    `アーティストを更新中にエラーが発生しました: ${message}`,
                            },
                        },
                        cover: {
                            head: "カバー",
                            musicstory: "Music-Story",
                            partner: "パートナー",
                            url: "カバーリンク",
                            ean: "バーコード",
                            create: {
                                title: "カバーを作成",
                                success: "カバーを作成しました",
                                error: (message) =>
                                    `カバーを作成中にエラーが発生しました: ${message}`,
                            },
                            update: {
                                title: "カバーを更新",
                                success: "カバーを更新しました",
                                error: (message) =>
                                    `カバーを更新中にエラーが発生しました: ${message}`,
                            },
                            automatic: {
                                success:
                                    "カバーアートは自動的に追加されました。",
                                error: (message) =>
                                    `カバーアート自動追加時のエラー : ${message}`,
                            },
                        },
                        review: {
                            head: "レビュー",
                            author: "エディター",
                            reset: {
                                title: "レビューをリセット",
                            },
                            update: {
                                title: "レビューを更新",
                                success: "レビューを更新しました",
                                error: (message) =>
                                    `レビューを更新中にエラーが発生しました: ${message}`,
                            },
                        },
                        translations: {
                            head: "翻訳",
                            language: "言語",
                            title: "タイトル",
                            subtitle: "サブタイトル",
                            languagePlaceholder: "言語を追加",
                            reset: {
                                title: "フォームをリセット",
                            },
                            update: {
                                title: "翻訳を更新",
                                success: "翻訳を更新しました",
                                error: (message) =>
                                    `翻訳を更新中にエラーが発生しました: ${message}`,
                            },
                        },
                    },
                    release: {
                        tabTitle: "リリース",
                        basic: {
                            head: "基本情報",
                            barcode: "バーコード",
                            release_date: "リリース日",
                            editor: "エディター",
                            support: "サポート",
                            commentary: "コメンタリー",
                            reference: "参照",
                            reset: {
                                title: "フォームをリセット",
                            },
                            create: {
                                title: "リリースを作成",
                                success: "リリースを作成しました",
                                error: (message) =>
                                    `リリースを作成中にエラーが発生しました: ${message}`,
                                warning: {
                                    title: "間違いないですか？",
                                    text: (message) =>
                                        `このリリースを作成しようとすると、次のような警告が表示されました。: ${message}`,
                                },
                            },
                            update: {
                                title: "リリースを更新",
                                success: "リリースを更新しました",
                                error: (message) =>
                                    `リリースを更新中にエラーが発生しました: ${message}`,
                            },
                            delete: {
                                title: "リリースを削除",
                                alert: {
                                    title: "間違いないですか？",
                                    text: "このリリースを削除してもいいですか？",
                                },
                                warning: {
                                    title: "間違いないですか？",
                                    text: (messages) =>
                                        `このリリースを削除しようとすると以下の警告が発生しました: \n\n ${messages.map(
                                            (message) => {
                                                return `- ${message} \n`;
                                            }
                                        )}`,
                                },
                                success: "リリースを削除",
                                error: (message) =>
                                    `リリースを削除中にエラーが発生しました: ${message}`,
                            },
                            transfer: {
                                title: "リリースを転送",
                                artist: "アーティスト",
                                album: "アルバム",
                                success: ({ artist, album, releaseId }) =>
                                    `リリースを "${releaseId}" アルバムの "${album}" アーティストに "${artist}" 転送する`,
                                error: (message) =>
                                    `リリースを転送中にエラーが発生しました: ${message}`,
                                same_album_error:
                                    "アルバムは現在リリースにリンクされているものであってはいけません",
                            },
                        },
                        matching: {
                            head: "パートナー",
                            empty: "結果なし",
                            delete: {
                                title: "マッチを削除",
                                alert: {
                                    title: "間違いないですか？",
                                    text: "このマッチを削除していいですか？",
                                },
                                success: "マッチを削除しました",
                                error: (message) =>
                                    `マッチを削除中にエラーが発生しました: ${message}`,
                            },
                        },
                        tracklist: {
                            head: "トラックリスト",
                            tracks: {
                                empty: "結果なし",
                                no_cd: "D",
                                no_piste: "T",
                                isrc: "ISRC",
                                title: "タイトル",
                                subtitle: "サブタイトル",
                                duration: "長さ",
                                hit: "ヒット",
                                discovery: {
                                    head: "EP",
                                    title: "エディターズピック",
                                },
                                validation: {
                                    no_cd: "ディスク番号は正の数でなければなりません",
                                    no_piste:
                                        "トラック番号は正の整数でなければなりません",
                                    title: "タイトルを空白にすることはできません",
                                    duration:
                                        "長さは 時:分:秒 の形式にする必要があります",
                                    duplicatePosition:
                                        "同じディスクとトラック番号を持つ別のトラックがあります",
                                    duplicateIsrc:
                                        "同じISRCを持つ別のトラックがあります",
                                },
                            },
                            reset: {
                                title: "トラックをリセット",
                            },
                            update: {
                                title: "トラックを更新",
                                success: "トラックが変更されました",
                                error: (message) =>
                                    `トラックを削除中にエラーが発生しました: ${message}`,
                                warningIsrc: {
                                    title: "間違いないですか？",
                                    text: "このトラックリストを更新しようとすると、次のような警告が表示されました。: 同じISRCを持つレコードが見つかりました",
                                },
                            },
                        },
                    },
                    track: {
                        tabTitle: "トラック",
                        basic: {
                            head: "基本情報",
                            title: "タイトル",
                            subtitle: "サブタイトル",
                            isrc: "ISRC",
                            duration: "時間",
                            no_cd: "CD番号",
                            no_piste: "トラック番号",
                            hit: "ヒット",
                            discovery: "エディターズピック",
                            language: "歌唱言語",
                            dialect: "方言",
                            genre: "ジャンル",
                            reset: {
                                title: "フォームをリセット",
                            },
                            create: {
                                title: "トラックを追加",
                                success: "トラックを追加しました",
                                error: (message) =>
                                    `トラックを追加中にエラーが発生しました: ${message}`,
                                warningIsrc: {
                                    title: "間違いないですか？",
                                    text: "このトラックを追加しようとすると、次のような警告が表示されました。: 同じISRCで録音したものが見つかりました",
                                },
                            },
                            update: {
                                title: "トラックを更新",
                                success: "トラックを更新しました",
                                error: (message) =>
                                    `トラックを更新中にエラーが発生しました: ${message}`,
                                warningIsrc: {
                                    title: "間違いないですか？",
                                    text: "このトラックを更新しようとすると、次のような警告が表示されました。: 同じISRCで録音したものが見つかりました",
                                },
                            },
                            delete: {
                                title: "トラックを削除",
                                alert: {
                                    title: "間違いないですか？",
                                    text: "このトラックを削除していいですか",
                                },
                                success: "トラックを削除",
                                error: (message) =>
                                    `トラックを削除中にエラーが発生しました: ${message}`,
                            },
                        },
                        translations: {
                            head: "翻訳",
                            language: "言語",
                            title: "タイトル",
                            subtitle: "サブタイトル",
                            languagePlaceholder: "言語を追加",
                            reset: {
                                title: "フォームをリセット",
                            },
                            update: {
                                title: "翻訳を更新",
                                success: "翻訳を更新しました",
                                error: (message) =>
                                    `翻訳を更新中にエラーが発生しました: ${message}`,
                            },
                        },
                        matching: {
                            head: "パートナー",
                            empty: "結果なし",
                            delete: {
                                title: "マッチを削除",
                                alert: {
                                    title: "間違いないですか？",
                                    text: "このマッチを削除していいですか？",
                                },
                                success: "マッチを削除しました",
                                error: (message) =>
                                    `マッチを削除中にエラーが発生しました: ${message}`,
                            },
                        },
                    },
                },
            },
            match: {
                empty: "マッチ無し",
                id: "ID",
                name: "名前",
                type_match: "タイプ",
                status: "ステータス",
                pending: "マッチのプロセス中です...",
                success: "マッチが完了しました",
            },
            imageCuration: {
                head: "ギャラリー",
                empty: "ギャラリーの中に画像がありません",
                width: "幅",
                height: "高さ",
                created_at: "作成日",
                copyright: "著作権",
                status: "ステータス",
                edit: "修正",
                edit_image: "画像情報の編集",
                delete: "削除",
                delete_image: "画像を削除",
            },
            imageGetty: {
                gallery: {
                    head: "ゲッティ",
                    empty: "ギャラリーの中に画像がありません",
                    width: "幅",
                    height: "高さ",
                    created_at: "作成日",
                    copyright: "著作権",
                    status: "ステータス",
                    edit: "修正",
                    edit_image: "画像情報の編集",
                    delete: "削除",
                    delete_image: "画像を削除",
                },
            },
            imageLabel: {
                head: "ギャラリー",
                empty: "ギャラリー内に画像がありません",
                width: "幅",
                height: "高さ",
                created_at: "作成日",
                copyright: "著作権",
                status: "ステータス",
            },
            chartsList: {
                head: "チャート",
                empty: "結果なし",
                source: "参照",
                area: "エリア",
                format: "フォーマット",
                country: "国",
                origin: "元",
                date_out: "出た日",
                track_title: "タイトル",
                title: "チャート名",
                country: "国",
                date_in: "チャートイン日",
                date_out: "チャートアウト日",
                nb_week: "週数",
                rank_in: "ランクイン",
                max_rank: "最高ランク",
                rank_out: "ランクアウト",
            },
            trackingPlanning: {
                head: "計画履歴",
                empty: "結果なし",
                date: "日付",
                title: "タイトル",
                album: "アルバム",
                category: "カテゴリー",
                reason: "理由",
                classification: "分類",
                tasks: "タスク",
                status: {
                    TODO: "ToDo",
                    COMPLETED: "完了",
                    TO_BE_CORRECTED: "修正予定",
                    VALIDATED_WITHOUT_CONTROL: "コントロールなしで認証済",
                    VERIFIED_AND_VALIDATED: "検証済みおよび認証済み",
                    ERROR: "エラー",
                },
            },
            trackingPicture: {
                head: "画像作業履歴",
                empty: "結果なし",
                picture: "画像",
                date: "日付",
                username: "ユーザー",
                action: "実行",
                curation_cms_artist_image_add_completed:
                    "キューレーション画像が追加されました",
                curation_cms_artist_image_update_completed:
                    "キューレーション画像が更新されました",
                curation_cms_artist_image_main_completed:
                    "キューレーション画像がメインとしてセットされました",
                curation_cms_artist_image_delete_completed:
                    "キューレーション画像が削除されました",
                label_cms_artist_picture_status_change_completed:
                    "レーベル画像のステータスが変更されました",
                getty_cms_artist_picture_add_completed:
                    "ゲッティ画像が追加されました",
                label_cms_artist_picture_add_completed:
                    "レーベル画像が追加されました",
                getty_cms_artist_picture_delete_completed:
                    "ゲッティ画像が削除されました",
                label_cms_artist_picture_delete_completed:
                    "レーベル画像が削除されました",
                getty_cms_artist_picture_check_completed:
                    "画像がチェックされました",
                getty_move_picture_completed: "ゲッティ画像が移動されました",
                label_move_picture_completed: "レーベル画像が移動されました",
            },
            trackingLog: {
                head: "ログ",
                empty: "結果なし",
                date: "日付",
                username: "ユーザー",
                action: "実行",
                cms_artist_alias_create_completed: "エイリアスが作成されました",
                cms_artist_alias_update_completed: "エイリアスが更新されました",
                cms_artist_alias_delete_completed: "エイリアスが削除されました",
                cms_artist_biography_create_completed:
                    "バイオグラフィーが作成されました",
                cms_artist_biography_update_completed:
                    "バイオグラフィーが更新されました",
                cms_artist_biography_translate_completed:
                    "バイオグラフィーが翻訳された",
                cms_artist_collaboration_create_completed:
                    "コラボレーションが作成されました",
                cms_artist_collaboration_delete_completed:
                    "コラボレーションが削除されました",
                cms_artist_collaboration_update_completed:
                    "コラボレーションが更新されました",
                cms_artist_contribution_create_completed:
                    "コントリビューションが作成されました",
                cms_artist_contribution_delete_completed:
                    "コントリビューションが削除されました",
                cms_artist_contribution_update_completed:
                    "コントリビューション更新されました",
                cms_artist_create_completed: "アーティストが作成されました",
                cms_artist_update_completed: "アーティストが更新されました",
                cms_artist_delete_completed: "アーティストが削除されました",
                cms_artist_genre_create_completed: "ジャンルが作成されました",
                cms_artist_genre_delete_completed: "ジャンルが削除されました",
                cms_artist_genre_update_completed: "ジャンルが更新されました",
                cms_artist_group_create_completed: "グループが作成されました",
                cms_artist_group_delete_completed: "グループが削除されました",
                cms_artist_group_update_completed: "グループが更新されました",
                cms_artist_member_create_completed: "メンバーが作成されました",
                cms_artist_member_delete_completed: "メンバーが削除されました",
                cms_artist_member_update_completed: "メンバーが更新されました",
                cms_artist_name_create_completed: "名前が作成されました",
                cms_artist_name_update_completed: "名前が更新されました",
                cms_artist_name_delete_completed: "名前が削除されました",
                cms_artist_related_create_completed:
                    "関連アーティストが作成されました",
                cms_artist_related_delete_completed:
                    "関連アーティストが削除されました",
                cms_artist_related_update_completed:
                    "関連アーティストが更新されました",
                cms_artist_role_update_completed: "役割が更新されました",
                cms_artist_site_create_completed: "サイトが作成されました",
                cms_artist_site_delete_completed: "サイトが削除されました",
                cms_artist_site_update_completed: "サイトが更新されました",
                cms_artist_social_create_completed: "SNSが作成されました",
                cms_artist_social_delete_completed: "SNSが削除されました",
                cms_artist_social_update_completed: "SNSが更新されました",
                cms_artist_wikipedia_create_completed:
                    "ウィキペディアが作成されました",
                cms_artist_wikipedia_update_completed:
                    "ウィキペディアが更新されました",
            },
        },
        delete: {
            breadcrumb: "アーティスト削除",
            empty: "真空",
            object: "対象",
            nb: "有効",
            url: "リンク",
            album: "アルバム",
            alias: "エイリアス",
            bio: "ミュージックストーリー",
            collab: "コラボレーションとコントリビューション",
            member_group: "メンバーとグループ",
            picture_curation: "画像キューレーション",
            picture_right: "権利付き画像",
            plannings: "プランニング",
            related1: "関連がある",
            related2: "と関連",
            site: "ウェブサイト",
            social: "SNS",
            wiki: "ウィキペディア",
            delete: {
                title: "アーティスト削除",
                alert: {
                    title: "本当ですか？",
                    text: "注意！重要なモノは必ずアーティストと関係がある。削除は元に戻せません、本当に続けますか？",
                },
                success: "アーティストの削除に成功",
                error: (message) => `アーティスト削除のエラー : ${message}`,
            },
        },
    },
    album: {
        view: {
            breadcrumb: "アルバムビジュアライゼーション",
            id: "Music-Story ID",
            title: "タイトル",
            subtitle: "サブタイトル",
            genre: "名前",
            profile: "レコーディング・エクスプローラー",
            univers: "アルバム",
            cover: "カバー",
            credits: "クレジット",
            review: "レビュー",
            charts: "トップソング",
            similar: "類似アルバム",
            noData: {
                profile: "このアルバムのトラックリストはありません",
                credits: "このアルバムにはクレジットがありません",
            },
            profileInformation: {
                head: "詳細情報",
                type: {
                    head: "タイプ",
                    live: "ライブ",
                    remix: "リミックス",
                    compilation: "コンピレーション",
                    karaoke: "カラオケ",
                    ring: "リング",
                    other: "その他",
                    original: "オリジナル",
                },
                format: {
                    head: "フォーマット",
                    album: "アルバム",
                    ep: "ミニアルバム(EP)",
                    single: "シングル",
                    mixtape: "ミックステープ",
                    mcd: "マキシ",
                },
                label: "レーベル",
                release_date: "オリジナル発売日",
                performers: "出演者",
                featurings: "アーティスト・アソシエイト",
                translations: "翻訳",
                reference: "レート",
            },
            profileReleases: {
                head: "リリース",
                subtitle:
                    "リリースをクリックすると、関連するISRCが表示されます。",
                description:
                    "アルバムのリリース一覧です。リリースをクリックすると、関連するISRCが表示されます。選択されたリリースは青で強調表示され、関連するISRCは黄色で強調表示されます。",
                ean: "バーコード",
                id: "Id",
                reference: "参照",
                nb_cd: "CDの枚数",
                nb_track: "トラック数",
                release_date: "リリース日",
                commentary: "コメンタリー",
                support: {
                    head: "サポート",
                    ep: "EP",
                    num: "デジタル",
                    dvd: "DVD",
                    lp: "LP",
                    "2cd": "2CD",
                    k7: "K7",
                    cd: "CD",
                },
                cline: "CLINE",
                pline: "PLINE",
                links: "リンク集",
                empty: "結果なし",
            },
            profileIsrcs: {
                head: "Isrcs",
                subtitle: "ISRCをクリックすると関連リリースが表示されます。",
                description:
                    "アルバムに関連する録音の一覧です。ISRCをクリックすると、そのISRCが含まれるリリースが表示されます。選択されたISRCは青でハイライトされ、関連するリリースは黄色でハイライトされる。",
                isrc: "Isrc",
                id: "Id",
                title: "タイトル",
                duration: "長さ",
                complementary: "関連コンテンツ",
                has_credits: "クレジット",
                has_lyric: "歌詞",
                has_audio: "オーディオディスクリプション",
                links: "リンク集",
                empty: "結果なし",
            },
            profileTracklist: {
                head: "トラックリスト",
                release_head: (id) =>
                    `選択したエディションに対応したトラックリスト "${id}"`,
                isrc_head: (isrc) => `選択したISRCに対応するトラック "${isrc}"`,
                id_release: "リリースID",
                ean: "バーコード",
                id_track: "トラックID",
                no_cd: "ディスク番号",
                no_piste: "トラック番号",
                id_recording: "録音ID",
                title: "タイトル",
                subtitle: "サブタイトル",
                isrc: "ISRC",
                duration: "長さ",
                hit: "ヒットスコア",
                editorial_pick: "エディターズ・ピック・スコア",
                empty: "結果なし",
            },
            universGenres: {
                head: "ジャンル",
                principal: "主",
                secondary: "サブ",
            },
            universLanguages: {
                head: "歌われる言語",
                principal: "主な言語",
                secondary: "その他の言語",
            },
            universInstruments: {
                head: "インストゥルメント",
            },
            universAudioDescription: {
                head: "オーディオディスクリプション",
                licence: "Simbals技術を使って算出したディスクリプタ",
                moods: {
                    title: "Moods of the album",
                    subtitle: "by order of importance",
                    description: () => (
                        <>
                            Detailed moods of the recording and their
                            corresponding weights. <br />
                            <strong>Full list:</strong> happy, sad, calm,
                            energic, excited, astonished/aroused,
                            tense/alarmed/afraid, angry, distressed, delighted,
                            convinced, impatient, frustrated, discontented,
                            pleased, interested, neutral, distrustful,
                            miserable/disappointed/startled, serene/content,
                            Confident, Worried, uncomfortable,
                            sad/depressed/gloomy, relaxed/satisfied,
                            contemplative, tired/sleepy, droopy, bored/anxious
                        </>
                    ),
                },
                themes: {
                    title: "This album contains tracks with the following themes",
                    description:
                        "Detailed themes of the recording related to the listening contexts.  Includes, for example, the following values: 'Workout', 'Diner', 'Christmas', ‘Party’, ‘Video Games’, 'Summer’, etc…",
                },
                timbres: {
                    title: "Contains titles with the following timbres",
                    description:
                        "Tags corresponding to the timbre of the recording.",
                },
                arousal: {
                    title: "Arousal",
                    description: () => (
                        <>
                            Arousal represents a perceptual measure of a
                            recording related to its intensity, energy and
                            activity.
                            <ul>
                                <li>
                                    <strong>High value</strong> usually
                                    corresponds to high energy tracks (hard
                                    rock, metal)
                                </li>
                                <li>
                                    <strong>Low value</strong> is given to calm
                                    tracks (slow, ballad).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Valence",
                    description: () => (
                        <>
                            Valence represents a perceptual measure of mood,
                            related to positive or negative feelings, for
                            example joy/sadness.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    tracks with positive feelings
                                </li>
                                <li>
                                    <strong>Low value</strong> refer to sad
                                    tracks.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Instrumentalness",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not vocals.
                            <ul>
                                <li>
                                    <strong>High value</strong> indicates that
                                    the track probably contains no vocal sounds
                                    (instrumental)
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to track with vocal content
                                    (singing words, rap, slam or speech for
                                    example).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Speechiness",
                    description: () => (
                        <>
                            This descriptor indicates speech content in a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> is related to
                                    the presence of spoken words (interview,
                                    audio book, poetry, commentary).
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to music, eventually with vocals
                                    (singing tracks), but without speech parts.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Acousticness indicator",
                    description: () => (
                        <>
                            This descriptor indicates whether the track contains
                            acoustic content.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    acoustic tracks (acoustic guitar/piano,
                                    voice, classical music)
                                </li>
                                <li>
                                    <strong>Low value</strong> indicates tracks
                                    with electric content (electronic,
                                    distortion, audio effects).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Danceability",
                    description: () => (
                        <>
                            Danceability indicates if a track is likely to be
                            considered for dancing (perception of tempo and
                            rhythm, stability and regularity).
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    more danceable tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is related to
                                    tracks without any perceptual rhythm.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Liveness",
                    description: () => (
                        <>
                            This descriptor indicates the probability that the
                            track has been performed live.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    live tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is more probably
                                    related to studio recordings
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Melodicity",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not a clearly audible melody.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    pleasant, harmonic tracks, to which you feel
                                    like singing along
                                </li>
                                <li>
                                    <strong>Low value</strong> are more related
                                    to noises, soundscapes, or tracks with
                                    complex harmonies or melodies (classical,
                                    jazz, etc)
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonance",
                    description: () => (
                        <>
                            This descriptor indicates how dissonant is a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    tracks without clear harmony, noisy timbre
                                    for example, or with very complex harmonies
                                    (dissonant intervals such as tritones,
                                    seconds, etc)
                                </li>
                                <li>
                                    <strong>Low value</strong> correspond to
                                    tracks with simple harmony, clear melody,
                                    consonant harmonies (simple piano melodies
                                    for example)
                                </li>
                            </ul>
                        </>
                    ),
                },
            },
            creditsCreditsData: {
                licence: "Courtesy of Muso.Ai",
                musician: "音楽家",
                songwriter: "ソングライター",
                management: "マネジメント",
                engineer: "エンジニア",
                producers: "プロデューサー",
                organization: "組織",
                other: "その他",
                visual: "ビジュアル",
            },
        },
    },
    recording: {
        view: {
            breadcrumb: "録画の可視化",
            id: "Music-Story ID",
            title: "タイトル",
            subtitle: "サブタイトル",
            isrc: "ISRC",
            genre: "名前",
            profile: "プロフィール",
            credits: "クレジット",
            popularity: "人気",
            charts: "チャート",
            lyrics: "歌詞",
            audio_description: "オーディオディスクリプタ",
            similar: "類似の録音",
            work: "作品",
            noData: {
                lyrics: "この録音には、歌詞がありません",
                credits: "この録音にはクレジットがありません",
                charts: "この録音に対応したチャートはありません",
                audio: "この録音には、オーディオディスクリプタはありません。",
                popularity: "この録音には人気データがない",
                work: "この録音に関する作品データは利用できません。",
            },
            profileFeaturing: {
                head: "アーティスト・アソシエイト",
            },
            profileInstrument: {
                head: "インストゥルメント",
            },
            profileDuration: {
                head: "長さ",
            },
            profileRelatedIsrc: {
                head: "関連ISRC",
                has_credits: "クレジット",
                has_lyric: "歌詞",
                has_audio: "オーディオディスクリプション",
            },
            profileTranslation: {
                head: "タイトルの翻訳",
            },
            profileAlbum: {
                head: "アルバム",
                cover: "ポシェット",
                title: "タイトル",
                artists: "アーティスト",
                type: {
                    head: "タイプ",
                    live: "ライブ",
                    remix: "リミックス",
                    compilation: "コンピレーション",
                    karaoke: "カラオケ",
                    ring: "リング",
                    other: "その他",
                    original: "オリジナル",
                },
                format: {
                    head: "フォーマット",
                    album: "アルバム",
                    ep: "ミニアルバム(EP)",
                    single: "シングル",
                    mixtape: "ミックステープ",
                    mcd: "マキシ",
                },
                release_date: "オリジナル発売日",
                link: "ライアンス",
                empty: "結果なし",
            },
            chartsRecordingCharts: {
                charts: {
                    head: "チャート",
                    title: "タイトル",
                    search: "検索...",
                    empty: "結果なし",
                },
                filter: {
                    format: {
                        label: "タイプ",
                    },
                    reference: {
                        label: "公式",
                        description: "国別公式売上ランキング",
                    },
                },
                trend: {
                    head: "トレンド",
                    x: "日付",
                    y: "順位",
                    dynamicRank: "空のランクを削除",
                    dynamicDate: "空の日付を削除する",
                },
            },
            lyricsInformation: {
                head: "インフォメーション",
                licence: {
                    title: "提供：LyricFind",
                    subtitle:
                        "このコンテンツは、LyricFindから配信される特定のライセンスなしに使用することはできません。",
                },
                lfid: "Lyricfind ID",
                hfa_code: "HFAコード",
                publisher_credit: "出版社クレジット",
                territories: "領土",
                lyric: "オリジナル・リリック",
            },
            audioAudioDescription: {
                head: "オーディオディスクリプション",
                licence: "Simbals技術を使って算出したディスクリプタ",
                moods: {
                    title: "Moods of the recording",
                    subtitle: "by order of importance",
                    description: () => (
                        <>
                            Detailed moods of the recording and their
                            corresponding weights. <br />
                            <strong>Full list:</strong> happy, sad, calm,
                            energic, excited, astonished/aroused,
                            tense/alarmed/afraid, angry, distressed, delighted,
                            convinced, impatient, frustrated, discontented,
                            pleased, interested, neutral, distrustful,
                            miserable/disappointed/startled, serene/content,
                            Confident, Worried, uncomfortable,
                            sad/depressed/gloomy, relaxed/satisfied,
                            contemplative, tired/sleepy, droopy, bored/anxious
                        </>
                    ),
                },
                themes: {
                    title: "Themes",
                    description:
                        "Detailed themes of the recording related to the listening contexts.  Includes, for example, the following values: 'Workout', 'Diner', 'Christmas', ‘Party’, ‘Video Games’, 'Summer’, etc…",
                },
                timbres: {
                    title: "Timbres",
                    description:
                        "Tags corresponding to the timbre of the recording.",
                },
                arousal: {
                    title: "Arousal",
                    description: () => (
                        <>
                            Arousal represents a perceptual measure of a
                            recording related to its intensity, energy and
                            activity.
                            <ul>
                                <li>
                                    <strong>High value</strong> usually
                                    corresponds to high energy tracks (hard
                                    rock, metal)
                                </li>
                                <li>
                                    <strong>Low value</strong> is given to calm
                                    tracks (slow, ballad).
                                </li>
                            </ul>
                        </>
                    ),
                },
                valence: {
                    title: "Valence",
                    description: () => (
                        <>
                            Valence represents a perceptual measure of mood,
                            related to positive or negative feelings, for
                            example joy/sadness.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    tracks with positive feelings
                                </li>
                                <li>
                                    <strong>Low value</strong> refer to sad
                                    tracks.
                                </li>
                            </ul>
                        </>
                    ),
                },
                vocal_instrumental: {
                    title: "Instrumentalness",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not vocals.
                            <ul>
                                <li>
                                    <strong>High value</strong> indicates that
                                    the track probably contains no vocal sounds
                                    (instrumental)
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to track with vocal content
                                    (singing words, rap, slam or speech for
                                    example).
                                </li>
                            </ul>
                        </>
                    ),
                },
                music_speech: {
                    title: "Speechiness",
                    description: () => (
                        <>
                            This descriptor indicates speech content in a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> is related to
                                    the presence of spoken words (interview,
                                    audio book, poetry, commentary).
                                </li>
                                <li>
                                    <strong>Low value</strong> probably
                                    corresponds to music, eventually with vocals
                                    (singing tracks), but without speech parts.
                                </li>
                            </ul>
                        </>
                    ),
                },
                electric_acoustic: {
                    title: "Acousticness indicator",
                    description: () => (
                        <>
                            This descriptor indicates whether the track contains
                            acoustic content.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    acoustic tracks (acoustic guitar/piano,
                                    voice, classical music)
                                </li>
                                <li>
                                    <strong>Low value</strong> indicates tracks
                                    with electric content (electronic,
                                    distortion, audio effects).
                                </li>
                            </ul>
                        </>
                    ),
                },
                danceability: {
                    title: "Danceability",
                    description: () => (
                        <>
                            Danceability indicates if a track is likely to be
                            considered for dancing (perception of tempo and
                            rhythm, stability and regularity).
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    more danceable tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is related to
                                    tracks without any perceptual rhythm.
                                </li>
                            </ul>
                        </>
                    ),
                },
                studio_live: {
                    title: "Liveness",
                    description: () => (
                        <>
                            This descriptor indicates the probability that the
                            track has been performed live.
                            <ul>
                                <li>
                                    <strong>High value</strong> corresponds to
                                    live tracks
                                </li>
                                <li>
                                    <strong>Low value</strong> is more probably
                                    related to studio recordings
                                </li>
                            </ul>
                        </>
                    ),
                },
                melodicity: {
                    title: "Melodicity",
                    description: () => (
                        <>
                            This descriptor indicates whether a track contains
                            or not a clearly audible melody.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    pleasant, harmonic tracks, to which you feel
                                    like singing along
                                </li>
                                <li>
                                    <strong>Low value</strong> are more related
                                    to noises, soundscapes, or tracks with
                                    complex harmonies or melodies (classical,
                                    jazz, etc)
                                </li>
                            </ul>
                        </>
                    ),
                },
                dissonance: {
                    title: "Dissonance",
                    description: () => (
                        <>
                            This descriptor indicates how dissonant is a track.
                            <ul>
                                <li>
                                    <strong>High value</strong> correspond to
                                    tracks without clear harmony, noisy timbre
                                    for example, or with very complex harmonies
                                    (dissonant intervals such as tritones,
                                    seconds, etc)
                                </li>
                                <li>
                                    <strong>Low value</strong> correspond to
                                    tracks with simple harmony, clear melody,
                                    consonant harmonies (simple piano melodies
                                    for example)
                                </li>
                            </ul>
                        </>
                    ),
                },
                extra: {
                    rythm: "Rythm",
                    timbre: "Timbre",
                    harmony: "Harmony",
                    energy: "Energy",
                },
                articulation: {
                    title: "Articulation",
                    description: "Articulation",
                },
                rhythmic_stability: {
                    title: "Rhythmic stability",
                    description: "Rhythmic stability",
                },
                event_density: {
                    title: "Event density",
                    description: "Event density",
                },
                pulse_clarity: {
                    title: "Pulse clarity",
                    description: "Pulse clarity",
                },
                bpm: {
                    title: "BPM",
                    description: "BPM",
                },
                complexity: {
                    title: "Complexity",
                    description: "Complexity",
                },
                binary: {
                    title: "Binary",
                    description: "Binary",
                },
                roll_off: {
                    title: "Roll off",
                    description: "Roll off",
                },
                brightness: {
                    title: "Brightness",
                    description: "Brightness",
                },
                zero_cross_rate: {
                    title: "Zero cross rate",
                    description: "Zero cross rate",
                },
                mfcc: {
                    title: "MFCC",
                    description: "MFCC",
                },
                mfcc01: {
                    title: "Mfcc01",
                },
                mfcc02: {
                    title: "Mfcc02",
                },
                mfcc03: {
                    title: "Mfcc03",
                },
                mfcc04: {
                    title: "Mfcc04",
                },
                mfcc05: {
                    title: "Mfcc05",
                },
                mfcc06: {
                    title: "Mfcc06",
                },
                mfcc07: {
                    title: "Mfcc07",
                },
                mfcc08: {
                    title: "Mfcc08",
                },
                mfcc09: {
                    title: "Mfcc09",
                },
                mfcc10: {
                    title: "Mfcc10",
                },
                mfcc11: {
                    title: "Mfcc11",
                },
                mfcc12: {
                    title: "Mfcc12",
                },
                mfcc13: {
                    title: "Mfcc13",
                },
                chroma: {
                    title: "Chroma (Harmony, key)",
                    description: "Chroma (Harmony, key)",
                },
                chroma01: {
                    title: "Chroma01",
                },
                chroma02: {
                    title: "Chroma02",
                },
                chroma03: {
                    title: "Chroma03",
                },
                chroma04: {
                    title: "Chroma04",
                },
                chroma05: {
                    title: "Chroma05",
                },
                chroma06: {
                    title: "Chroma06",
                },
                chroma07: {
                    title: "Chroma07",
                },
                chroma08: {
                    title: "Chroma08",
                },
                chroma09: {
                    title: "Chroma09",
                },
                chroma10: {
                    title: "Chroma10",
                },
                chroma11: {
                    title: "Chroma11",
                },
                chroma12: {
                    title: "Chroma12",
                },
                complexity_chroma: {
                    title: "Complexity chroma",
                    description: "Complexity chroma",
                },
                intensity: {
                    title: "Intensity",
                    description: "Intensity",
                },
                loudness: {
                    title: "Loudness",
                    description: "Loudness",
                },
                absolute_loudness: {
                    title: "Absolute loudness",
                    description: "Absolute loudness",
                },
                loudness_range: {
                    title: "Loudness range",
                    description: "Loudness range",
                },
                centroid: {
                    title: "Centroid",
                    description: "Centroid",
                },
                spread: {
                    title: "Spread",
                    description: "Spread",
                },
                flatness: {
                    title: "Flatness",
                    description: "Flatness",
                },
            },
            creditsCreditsData: {
                licence: "提供：Music.Ai",
                musician: "音楽家",
                songwriter: "ソングライター",
                management: "マネジメント",
                engineer: "エンジニア",
                producers: "プロデューサー",
                organization: "組織",
                other: "その他",
                visual: "ビジュアル",
            },
            popularityOverview: {
                head: "概要",
                global: {
                    title: "グローバル",
                    weeks: "チャートにおける週数：",
                    description:
                        "楽曲の人気度は、私たちが追跡しているチャートにおけるその楽曲の存在と位置に基づいて計算されます。ランク（私たちの人気タイトルランキングにおけるタイトルの位置）と人気レベル（私たちのランキングで最も高い人気スコアに対する相対的な％）の2つの形式があります。",
                },
                gold: {
                    title: "過去",
                    weeks: "カタログチャートの週数：",
                    description:
                        "タイトルのカタログ人気は、18か月以上前の公式チャートにおけるその存在に基づいています。カタログ人気は、公式のアメリカ、ドイツ、イギリスのチャートのデータのみを考慮に入れます。",
                },
                current: {
                    title: "現在",
                    weeks: "現在のチャートにおける週数：",
                    description:
                        "現在のタイトルの人気は、過去18か月以内のチャートにおけるその存在に基づいています。",
                },
                type: {
                    label: "タイプ：",
                    gold: "オールディーズ",
                    current: "現在",
                },
                peak: "ピーク：",
            },
            popularityHistogram: {
                head: "年別の人気度",
                tabs: {
                    gold: {
                        title: "過去",
                        description:
                            "アーティストのカタログ人気は、18ヶ月以上前のチャートでの存在に基づいています。カタログ人気は、現在Billboard Hot 100、UK Official Singles Chart & Offizielle Deutsche Chartsのデータのみを使用しています。",
                        header: "カタログ人気はUS、UK、DEの公式チャートデータのみに基づいており、より多くの国が統合されている。",
                    },
                    current: {
                        title: "現在",
                        description:
                            "アーティストの現在の人気は、過去18ヶ月間のチャートでの存在感に基づいています。",
                    },
                },
                gold: "ゴールドの人気",
                current: "現在の人気",
                popover: {
                    noData: "データなし",
                    popularity: "レコーディングの人気：",
                    bestRank: "ベストランクです：",
                    bestCountry: "最高の国です：",
                    charts: (n) => `${n}枚のチャートに基づいています。`,
                },
            },
            popularityMap: {
                head: "国別人気度",
                tabs: {
                    gold: {
                        title: "過去",
                        description:
                            "アーティストのカタログ人気は、18ヶ月以上前のチャートでの存在に基づいています。カタログ人気は、現在Billboard Hot 100、UK Official Singles Chart & Offizielle Deutsche Chartsのデータのみを使用しています。",
                    },
                    current: {
                        title: "現在",
                        description:
                            "アーティストの現在の人気は、過去18ヶ月間のチャートでの存在感に基づいています。",
                    },
                },
                popover: {
                    noData: "データなし",
                    popularity: "レコーディングの人気：",
                    bestRank: "ベストランクです：",
                    bestMonth: "ベストランクの月：",
                    weeksInCharts: "チャートで見る週数：",
                    charts: (n) => `${n}枚のチャートに基づいています。`,
                },
            },
            workData: {
                iswc: "ISWC",
                hfa: "HFAコード",
                publisher_credit: "出版社クレジット (Lyricfind)",
                share: {
                    head: "米国のシェア (MLC)",
                    party: "当事者",
                    ipi: "IPI",
                    role: "役割",
                    parent: "親会社当事者",
                    parent_ipi: "親会社IPI",
                    share: "シェア",
                },
            },
        },
    },
    genre: {
        breadcrumb: "ジャンル",
        genre: {
            head: "ジャンル",
            parent: "親ジャンル名",
            name: "名前",
        },
        related: {
            head: "関連",
            name: "名前",
            weight: "タイプ",
        },
        artist: {
            head: "トップアーティスト",
            position: "N°",
            name: "名前",
            complement: "補足",
            actions: "実行",
            country: "国",
            type: "タイプ",
            role: "役割",
        },
        button: "ジャンル管理",
        search: "検索...",
        empty: "結果なし",
        manage: {
            breadcrumb: "管理",
            genre: {
                head: "ジャンル",
                empty: "結果なし",
                id: "ID",
                name: "名前",
                description: "説明",
                related: "関連",
                genre: "ジャンル",
                type: "タイプ",
                genre_fr: "ジャンル　フランス語",
                genre_en: "ジャンル　英語",
                other_translations: "他の翻訳",
                parent_genre: "親ジャンル",
                parent_genre_placeholder:
                    "親ジャンルを作成するために何も選択しないでください",
                created_at: "で作成",
                updated_at: "で更新",
                action: "実行",
                search: "検索...",
            },
            create: {
                title: "ジャンルを作成",
                success: "ジャンルを作成しました",
                error: (message) =>
                    `ジャンルを作成中にエラーが発生しました: ${message}`,
            },
            update: {
                title: "ジャンルを更新",
                success: "ジャンルを更新しました",
                error: (message) =>
                    `ジャンルを更新中にエラーが発生しました: ${message}`,
            },
            delete: {
                title: "ジャンルを削除",
                alert: {
                    title: "間違いないですか？",
                    text: "このジャンルを削除してもいいですか？",
                },
                success: "ジャンルを削除",
                error: (message) =>
                    `ジャンルを削除中にエラーが発生しました: ${message}`,
            },
        },
    },
    country: {
        breadcrumb: "国",
        head: "国",
        button: "国管理",
        code: "コード",
        country_fr: "国　フランス語",
        country_en: "国　英語",
        country_ja: "国　日本語",
        country_de: "国　ドイツ語",
        country_ko: "国　韓国語",
        search: "検索...",
        empty: "表示する国がありません",
        manage: {
            breadcrumb: "管理",
            country: {
                head: "国",
                empty: "結果なし",
                id: "ID",
                code: "コード",
                country_fr: "国　フランス語",
                country_en: "国　英語",
                other_translations: "他の翻訳",
                created_at: "で作成",
                updated_at: "で更新",
                action: "実行",
                search: "検索...",
            },
            create: {
                title: "国を作成",
                success: "国を作成しました",
                error: (message) =>
                    `国を作成中にエラーが発生しました: ${message}`,
            },
            update: {
                title: "国を更新",
                success: "国を更新しました",
                error: (message) =>
                    `国を更新中にエラーが発生しました: ${message}`,
            },
            delete: {
                title: "国を削除",
                alert: {
                    title: "間違いないですか？",
                    text: "この国を削除してもいいですか？",
                },
                success: "Country deleted",
                error: (message) =>
                    `国を削除中にエラーが発生しました: ${message}`,
            },
        },
    },
    misc_entity: {
        breadcrumb: "ショー",
        head: "ショー",
        search: "検索...",
        empty: "表示するその他はありません",
        id: "id",
        name: "名前",
        type: "タイプ",
        description: "説明",
        date: "日付",
        management: {
            breadcrumb: "Management",
            profile: "プロフィール",
            curation: "キューレーション",
            getty: "Getty",
            main: "メイン",
            misc_entity: {
                id: "id",
                name: "名前",
                type: "タイプ",
                description: "説明",
                date: "日付",
                created_at: "作成日",
                updated_at: "編集日",
                action: "実行",
                search: "検索...",
            },
            imageCuration: {
                head: "ギャラリー",
                empty: "ギャラリーの中に画像がありません",
                width: "幅",
                height: "高さ",
                created_at: "作成日",
                copyright: "著作権",
                status: "ステータス",
                edit: "修正",
                edit_image: "画像情報の編集",
                delete: "削除",
                delete_image: "画像を削除",
            },
            imageGetty: {
                gallery: {
                    head: "ゲッティ",
                    empty: "ギャラリーの中に画像がありません",
                    width: "幅",
                    height: "高さ",
                    created_at: "作成日",
                    copyright: "著作権",
                    status: "ステータス",
                    edit: "修正",
                    edit_image: "画像情報の編集",
                    delete: "削除",
                    delete_image: "画像を削除",
                },
            },
            create: {
                title: "その他のエンティティの作成",
                success: "その他の作成",
                error: (message) =>
                    `Error while creating this misc: ${message}`,
            },
            update: {
                title: "その他の更新",
            },
            delete: {
                title: "その他を削除",
                alert: {
                    title: "Are you sure ?",
                    text: "To want to delete this misc ?",
                },
                success: "その他を削除しました",
                error: (message) =>
                    `Error while deleting this misc: ${message}`,
            },
        },
    },
    role: {
        breadcrumb: "役割",
        head: "役割",
        button: "役割管理",
        role_fr: "役割　フランス語",
        role_en: "役割　英語",
        role_ja: "役割　日本語",
        role_de: "役割　ドイツ語",
        role_ko: "役割　韓国語",
        search: "検索...",
        empty: "表示する役割がありません",
        manage: {
            breadcrumb: "管理",
            role: {
                head: "役割",
                empty: "結果なし",
                id: "ID",
                role_fr: "役割　フランス語",
                role_en: "役割　英語",
                other_translations: "他の翻訳",
                created_at: "で作成",
                updated_at: "で更新",
                action: "実行",
                search: "検索...",
            },
            create: {
                title: "役割を作成",
                success: "役割を作成しました",
                error: (message) =>
                    `役割を作成中にエラーが発生しました: ${message}`,
            },
            update: {
                title: "役割を更新",
                success: "役割を更新しました",
                error: (message) =>
                    `役割を更新中にエラーが発生しました: ${message}`,
            },
            delete: {
                title: "役割を削除",
                alert: {
                    title: "間違いないですか？",
                    text: "この役割を削除してもいいですか？",
                },
                success: "役割を削除しました",
                error: (message) =>
                    `役割を削除中にエラーが発生しました: ${message}`,
            },
        },
    },
    plannings: {
        breadcrumb: "プラン",
        manage: {
            breadcrumb: "プラン管理",
            planning: {
                empty: "結果なし",
                building: "作成中",
                progress: "進行中",
                done: "アーカイブ済",
                title: "タイトル",
                startDate: "開始日",
                endDate: "終了日",
                stats: "進行状況",
                itemsCount: "結果",
            },
            create: {
                title: "プランを作成",
            },
            update: {
                title: "プランを更新",
            },
        },
        view: {
            breadcrumb: "プランを見る",
            partner: {
                all: "概要",
            },
            action: {
                validate: {
                    title: "プランを検証",
                    swal: {
                        title: "間違いないですか？",
                        notCompleted: "プランは完全には完了していません",
                        text: "このプランを検証してもいいですか？",
                    },
                },
                deleted: {
                    title: "プランを削除",
                    swal: {
                        title: "間違いないですか？",
                        tasksExist: "プランは空ではありません",
                        text: "このプランを削除してもいいですか？",
                    },
                },
            },
            tasks: {
                title: "タスク",
                empty: "結果なし",
                manager: "マネージャー",
                status: "ステータス",
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                category: "カテゴリー",
                assignement: "課題",
                classification: "クラス.",
                tasks: "タスク",
                reason: "理由",
                information: "情報",
                nbRelated: "関連数",
                albumsWithoutGenre: "ジャンル無しのアルバム",
                hasBioFR: "フランス語のバイオグラフィーがあります",
                hasBioEN: "英語のバイオグラフィーがあります",
                hasBioDE: "ドイツ語のバイオグラフィーがあります",
                hasBioJA: "日本語の伝記がある",
                hasBioES: "スペイン語のバイオグラフィーあり",
                hasBioPT: "ポルトガル語のバイオグラフィーがある",
                hasReview: "レビューがあります",
                hasPicture: "画像があります",
                hasCuration: "キューレーションがあります",
                lastUpdated: "最後のプランニング・エントリー",
                genre: "ジャンル",
                language: "言語",
                country: "国",
                action: {
                    topLink: "関連トップ",
                    lyricsSampleLink: "歌詞の評価",
                    discoLink: "ディスコグラフィー",
                    create: {
                        title: "タスクを作成",
                    },
                    update: {
                        title: "タスクを更新",
                    },
                    delete: {
                        title: "タスクを削除",
                        alert: {
                            title: "間違いないですか？",
                            text: "このタスクを削除していいですか？",
                        },
                    },
                },
                subtasks: {
                    change_status: "ステータスを変更 :",
                    confirm_change_status: {
                        TODO: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスを ToDo に変更しますか?",
                        },
                        COMPLETED: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスを完了に変更しますか？",
                        },
                        TO_BE_CORRECTED: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスを修正対象に変更しますか?",
                            comment: {
                                title: "コメントを追加",
                                error: "コメントは必須です",
                            },
                        },
                        VERIFIED_AND_VALIDATED: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスを検証・認証済に変更しますか?",
                        },
                        VALIDATED_WITHOUT_CONTROL: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスをコントロールなしで認証済に変更しますか?",
                        },
                        ERROR: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスをエラー中に変更しますか?",
                            comment: {
                                title: "コメントを追加",
                            },
                        },
                    },
                    status: {
                        UNASSIGNED: "割り当て無し",
                        TODO: "ToDo",
                        COMPLETED: "完了済",
                        TO_BE_CORRECTED: "修正対象",
                        VALIDATED_WITHOUT_CONTROL: "コントロールなしで認証済",
                        VERIFIED_AND_VALIDATED: "検証・認証済",
                        ERROR: "エラー",
                    },
                },
            },
            stats: {
                title: "統計",
                manager: "マネージャー",
            },
            progress: {
                title: "進行状況",
                username: "ユーザーネーム",
            },
            budget: {
                title: "予算",
                username: "ユーザーネーム",
                task_type: "タスクタイプ",
            },
            history: {
                title: "履歴を見る",
                head: "履歴",
                empty: "結果なし",
                search: "アーティストを検索",
                action: {
                    created_by: "により作成",
                    deleted_by: "により削除",
                    modified_by: "により変更",
                    release_matched_by: "によりアルバムマッチ",
                    release_unmatched_by: "によりアルバムアンマッチ",
                    track_matched_by: "によりトラックマッチ",
                    track_unmatched_by: "によりトラックアンマッチ",
                    status_changed_by: (new_status) =>
                        `により ${new_status} としてマーク済`,
                    status_comment: (comment) => `コメント : ${comment}`,
                },
            },
        },
        tasks: {
            planning: "プランニング",
            artist: "アーティスト",
            nbRelated: "関連数",
            hasBioFR: "フランス語のバイオグラフィーがあります",
            hasBioEN: "英語のバイオグラフィーがあります",
            hasBioDE: "ドイツ語のバイオグラフィーがあります",
            hasBioJA: "日本語の伝記がある",
            hasBioES: "スペイン語のバイオグラフィーあり",
            hasBioPT: "ポルトガル語のバイオグラフィーがある",
            hasPicture: "画像があります",
            hasCuration: "キューレーションがあります",
            album: "アルバム",
            track: "トラック",
            category: "カテゴリー",
            classification: "分類",
            assignement: "課題",
            reason: "理由",
            error: {
                required: "このフィールドは必須です",
                albumTrackRequired:
                    "アルバムまたはトラックフィールドは必須です",
            },
            subtasks: {
                title: "タスク",
                typePlaceholder: "タスク...",
                assignementPlaceholder: "に影響...",
                new: "タスクを追加",
                remove: "タスクを削除",
                typeGroups: {
                    ART: "アーティスト",
                    ART_C: "アーティスト修正",
                    BIO_FR: "フランス語バイオグラフィー",
                    BIO_EN: "英語バイオグラフィー",
                    BIO_DE: "ドイツ語バイオグラフィー",
                    BIO_PT: "ポルトガル伝記",
                    BIO_JP: "日本のバイオグラフィー",
                    BIO_FR_C: "フランス語バイオグラフィー作成",
                    BIO_EN_C: "英語バイオグラフィー作成",
                    BIO_DE_C: "ドイツ語バイオグラフィー",
                    BIO_JP_C: "日本人の伝記添削",
                    RVW: "レビュー",
                    RVW_C: "修正をレビュー",
                    APL_AS: "歌詞の評価",
                    APL_CAS: "歌詞カウンター評価",
                },
                error: {
                    oneTypePerGroup: (category) =>
                        `${category} カテゴリには最大1つのタスクが必要です`,
                },
            },
        },
        production: {
            breadcrumb: "ランディングページ",
            industry: "市場統計",
            ms: "データベース統計",
            my_planning: "マイプラン",
            tasks: {
                empty: "スケジュールされたタスクはありません",
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                genre: "ジャンル",
                country: "国",
                category: "カテゴリー 理由",
                classification: "分類",
                tasks: "タスク",
                related_tasks: "Related Tasks",
                subtasks: {
                    change_status: "ステータスの変更:",
                    match_required: "アルバムまたはトラックでマッチが必要です",
                    confirm_change_status: {
                        COMPLETED: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスを完了に変更しますか?",
                        },
                        ERROR: {
                            title: "間違いないですか？",
                            text: "このタスクのステータスをエラーに変更しますか?",
                            comment: {
                                title: "コメントを追加",
                            },
                        },
                    },
                    status: {
                        TODO: "ToDo",
                        COMPLETED: "完了済",
                        TO_BE_CORRECTED: "修正対象",
                        VALIDATED_WITHOUT_CONTROL: "コントロールなしで検証済み",
                        VERIFIED_AND_VALIDATED: "検証・認証済",
                        ERROR: "エラー",
                    },
                },
            },
        },
        client: {
            title: "Music Story 制作プラン",
            sub_title: ({ title, start_date, end_date }) =>
                `から ${start_date} まで ${end_date} ${
                    title ? `(${title})` : ""
                }`,
            status: ({ status }) => {
                const translatedStatus = {
                    BUILDING: "ステータス : 作成中",
                    PROGRESS: "ステータス : 進行中",
                    DONE: "ステータス : アーカイブ済",
                };
                return translatedStatus[status];
            },
            tasks: {
                empty: "スケジュールされているタスクはありません",
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                category: "カテゴリー",
                status_artist: "アーティストプロフィール",
                status_bio_fr: "フランス語バイオグラフィー",
                status_bio_en: "英語バイオグラフィー",
                status_bio_de: "ドイツ語バイオグラフィー",
                status_bio_ja: "日本の伝記",
                status_review: "レビュー",
                status_picture: "画像",
                status_curation: "キューレーション",
                status: {
                    creation: "作成",
                    update: "更新",
                    new: "新規",
                    progress: "が作成されました",
                    exist: "すでに存在します",
                    missing: "ミッシング",
                },
            },
        },
        picture: {
            breadcrumb: "画像処理",
            planning: {
                head: "プランニング",
                title: "タイトル",
                empty: "結果なし",
                status: "ステータス",
                building: "作成中",
                progress: "進行中",
                done: "アーカイブ済",
                startDate: "開始日",
                endDate: "終了日",
            },
            tasks: {
                title: "タスク",
                empty: "結果なし",
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                latest_added_date: "最終追加日",
                status: "ステータス",
                information: "情報",
                label: "レーベル",
                getty: "ゲッティ",
                not_found: "見つかりません",
                comment: "コメント",
                last_label_created_at: "最終ゲッティ画像追加日",
                last_getty_created_at: "最終ゲッティ画像追加日",
                max_getty_date: "ゲッティ写真の最新日",
                has_disco: "ディスコ",
                last_check_date: "最終認証日",
                itemsCount: (count) => {
                    return `${count} 結果${count > 1 ? "s" : ""}`;
                },
            },
        },
        billing: {
            breadcrumb: "請求",
            empty: "請求書がありません",
            month: {
                period: ({ start_date, end_date }) =>
                    `から ${start_date} の期間 ${end_date}`,
            },
            subtasks: {
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                validated_at: "認証日",
            },
        },
        statistic: {
            breadcrumb: "統計",
            tracking_period: "期間ごとのトラッキング",
            chart_tracking: "チャートトラッキング",
            ressources_tracking: "リソースのトラッキング",
            planning_annual: "年間プラン",
            charts_annual_tracking: "チャート年間トラッキング",
            resources_annual_tracking: "リソース年間トラッキング",
        },
    },
    apple: {
        sample: {
            assessment: {
                informations: {
                    head: "情報",
                    catalogType: "カタログタイプ",
                    month: "月",
                    artist: "アーティスト",
                    album: "アルバム",
                    title: "タイトル",
                    duration: "長さ",
                    isrc: "ISRC",
                    genre: "ジャンル",
                    country: "国",
                    language: "言語",
                    status: {
                        TODO: "ToDo",
                        COMPLETED: "完了済",
                        TO_BE_CORRECTED: "修正対象",
                        VALIDATED_WITHOUT_CONTROL: "コントロールなしで検証済み",
                        VERIFIED_AND_VALIDATED: "検証・認証済",
                        ERROR: "エラー",
                    },
                    planning: "プラン",
                    manager: "マネージャー",
                },
                lyric: {
                    head: "歌詞",
                    empty: "結果なし",
                    nbLine: "ライン",
                    timing: "タイミング",
                    text: "テキスト",
                    rating: {
                        filter: "このラインのレートを見る",
                        criteria: "評価基準",
                        errorType: "エラータイプ",
                        comment: "コメント",
                        create: {
                            title: "レートを追加",
                            success: "レートが追加されました",
                            error: (message) =>
                                `レートを追加中にエラーが発生しました: ${message}`,
                        },
                    },
                },
                rating: {
                    reset: "全ての評価を見る",
                    line: (line_number) => `ライン : ${line_number}`,
                    criteria: "評価基準 :",
                    errorType: "エラータイプ :",
                    comment: "コメント :",
                    delete: {
                        tooltip: "レートを削除",
                        alert: {
                            title: "間違いないですか？",
                            text: "このレートを削除してもいいですか？",
                        },
                        success: "レートが削除されました",
                    },
                },
                player: {
                    action: {
                        previous: "最初に戻る (X)",
                        back10: "10秒戻る (C)",
                        pause: "音楽を停止 (SPACE)",
                        play: "音楽を再生 (SPACE)",
                    },
                    login: "ログイン",
                    logout: "ログアウト",
                    playerError: {
                        unavailable: "使用可能なドライブはありません",
                        restricted: "コンテンツ制限",
                    },
                },
                ratingType: {
                    assessment: "課題",
                    counterAssessment: " 対向課題",
                },
            },
            management: {
                sample: {
                    head: "サンプル",
                    empty: "結果なし",
                    date: "日付",
                },
                catalog: "カタログ",
                stats: "ステータス",
                progress: "進捗",
                lyrics: {
                    head: "歌詞",
                    empty: "結果なし",
                    catalog_type: "カテゴリータイプ",
                    provider: "プロバイダー",
                    user: "ユーザー",
                    artist: "アーティスト",
                    album: "アルバム",
                    title: "タイトル",
                    isrc: "ISRC",
                    genre: "ジャンル",
                    language: "言語",
                    country: "国",
                    assessment: "課題",
                    counter_assessment: "対向課題",
                    filters: {
                        planning: "プランニング",
                        assignement: "に影響",
                        nb_line: "行数",
                        assessment: {
                            yes: "課題付き",
                            no: "課題無し",
                        },
                        counter_assessment: {
                            yes: "対向課題付き",
                            no: "対向課題無し",
                        },
                    },
                    action: {
                        addToPlanning: {
                            title: "プランを追加",
                            exist: "すでにプランに追加されています",
                        },
                    },
                },
            },
            report: {
                sample: {
                    head: "サンプル",
                    empty: "結果なし",
                    date: "日付",
                },
                scorecard_provider: "プロバイダーをスコアカードする",
                scorecard_apple_user: "アップルユーザーをスコアカードする",
                ranking_language_genre: "言語/ジャンルのランキング",
                explorer: "探検",
                error: "エラーの詳細",
            },
        },
    },
    cnm: {
        repositories: {
            repositories: {
                title: "リポジトリ",
                empty: "結果なし",
            },
            years: {
                title: "年",
                empty: "結果なし",
            },
            upload: {
                button: {
                    title: "アップロード",
                },
                modal: {
                    title: "リポジトリをアップロード",
                    file: "ファイル",
                    file_placeholder: {
                        csv: "CSVファイルを選択",
                        json: "JSONファイルを選択",
                    },
                    browse: "ブラウザ",
                    type: "タイプ",
                    year: "年",
                    submit: "アップロード",
                    submitting: "アップローディング",
                },
                conflict: {
                    usedIn: ({ name }) => `${name} は使用されています `,
                    titleLink: ({ artist, title }) =>
                        `${title} による ${artist}`,
                },
                success: "リポジトリはアップロードされました",
            },
            csa_artists: {
                title: "CSA アーティスト",
                upload_help:
                    "CSVファイルはアーティスト名を含む一つのコラムを含んでいるべきで、ヘッダー行はいりません",
                empty: "結果なし",
                search: "検索...",
                name: "Name",
                conflict:
                    "以下の CSA アーティストは統合トップで使用されていますが、アップロードされたファイルにはありません:",
            },
            cnm_artists_titles: {
                title: "CNM アーティスト/タイトル",
                artists: {
                    head: "アーティスト",
                    search: "検索...",
                    id: "ID",
                    name: "名前",
                    msArtists: "MS アーティスト",
                    noArtist: "アーティストのないタイトル",
                    edit: {
                        tooltip: "アーティストを編集",
                        title: "アーティストを編集",
                        msArtistsPlaceholder: "アーティストを選択",
                        submit: "編集",
                        success: "アーティストが変更されました",
                    },
                    delete: {
                        tooltip: "アーティストを削除",
                        title: "アーティストを削除",
                        text: "本当にこのアーティストを削除しますか?",
                        submit: "削除",
                        success: "アーティストを削除しました",
                    },
                },
                titles: {
                    head: ({ noArtist, artistName }) =>
                        noArtist
                            ? "アーティストのないタイトル"
                            : artistName
                            ? `によるタイトル ${artistName}`
                            : "タイトル",
                    search: "検索...",
                    id: "ID",
                    artist: "アーティスト",
                    title: "タイトル",
                    msRecordings: "MS レコーディング",
                    artistPlaceholder: "アーティストを選択",
                    msRecordingsPlaceholder: "レコーディングを選択",
                    edit: {
                        tooltip: "タイトルを編集",
                        title: "タイトルを編集",
                        submit: "編集",
                        success: "タイトルが編集されました",
                    },
                    delete: {
                        tooltip: "タイトルを削除",
                        title: "タイトルを削除",
                        text: "このタイトルを削除してもいいですか？",
                        matchings:
                            "タイトルは次のプラットフォームのチャートでマッチしており、削除できません。",
                        position: (positions) => `ランク ${positions}`,
                        submit: "削除",
                        success: "タイトルは削除されました",
                    },
                },
            },
            cnm_genres: {
                title: "CNM ジャンル",
                upload_help:
                    "CSVファイルはジャンルを含む一つのコラムを含んでいるべきで、ヘッダー行はいりません",
                genres: {
                    title: "CNM ジャンル",
                    empty: "結果なし",
                    search: "検索...",
                    cnmGenre: "CNM ジャンル",
                    msGenres: "MS ジャンル",
                },
                matchings: {
                    title: "マッチング",
                    search: "検索...",
                    empty: "結果なし",
                    filter: {
                        msMainGenre: "メイン MS ジャンル",
                        cnmGenre: "CNM ジャンル",
                        notMatched: "マッチ無し",
                    },
                    msGenre: "MS ジャンル",
                    cnmGenre: "CNM ジャンル",
                    cnmGenrePlaceholder: "CNM ジャンルを選択",
                    matchSelection: {
                        button: "マッチセレクション",
                        title: "選択しているMS ジャンルとマッチ",
                        genrePlaceholder: "CNM ジャンルを選択",
                        modifiedMatchings:
                            "警告, 以下のマッチは置き換えられます:",
                        submit: "マッチ",
                    },
                    copyMatchings: {
                        button: "マッチをコピー",
                        title: "別の年のリポジトリからマッチをコピーする",
                        genrePlaceholder: "年を選択",
                        deletedGenres: (yearSrc) =>
                            `からジャンルを削除 ${yearSrc}:`,
                        newGenres: (yearDst) => `に新しいジャンル ${yearDst}:`,
                        modifiedMatchings: {
                            title: ({ yearSrc, yearDst }) =>
                                `警告: 次のMS ジャンルは、リポジトリ ${yearSrc} と ${yearDst} で異なるマッチがあります。 ${yearDst} からのマッチが保持されます:`,
                            item: ({
                                yearSrc,
                                yearDst,
                                msName,
                                dstCNMName,
                                srcCNMName,
                            }) =>
                                `${msName} (MS) – ${srcCNMName} 年 ${yearSrc} と ${dstCNMName} 年 ${yearDst} (CNM)`,
                        },
                        submit: "コピー",
                        success: "マッチがコピーされました",
                    },
                },
                matchingSuccess: "マッチが変更されました",
            },
            cnm_labels: {
                title: "CNM レーベル",
                upload_help:
                    "CSVファイルはレーベル名とタイプの2つの列を含んでいるべきで、ヘッダー行はいりません。",
                empty: "結果なし",
                search: "検索...",
                name: "名前",
                type: "タイプ",
                add: {
                    button: "追加",
                    tooltip: "レーベルを追加",
                    success: "レーベルが追加されました",
                },
                edit: {
                    tooltip: "レーベルを編集",
                    success: "レーベルが編集されました",
                },
                delete: {
                    tooltip: "レーベルを削除",
                    title: "レーベルが削除されました",
                    text: "このレーベルを削除してもいいですか？",
                    matchings:
                        "このレーベルは連結トップの次のタイトルで使用されています。:",
                    titleLink: ({ artist, title }) => `${title} by ${artist}`,
                    submit: "削除",
                    success: "レーベルが削除されました",
                },
                createOrUpdate: {
                    create: {
                        title: "レーベルを追加",
                        submit: "追加",
                    },
                    update: {
                        title: "レーベルを編集",
                        submit: "編集",
                    },
                    cnmName: "オリジナルCNM名",
                    name: "名前",
                    type: {
                        label: "タイプ",
                        placeholder: "タイプを編集",
                        major: "メジャー",
                        indie: "インディー",
                    },
                },
                conflict:
                    "以下のCNMレーベルは統合トップで使用されていますが、アップロードされたファイルにはありません:",
            },
            cnm_distributors: {
                title: "CNM ディストリビューター",
                upload_help:
                    "CSVファイルはディストリビューター名とタイプの2つの列を含んでいるべきで、ヘッダー行はいりません。",
                empty: "結果なし",
                search: "検索...",
                name: "名前",
                type: "タイプ",
                add: {
                    button: "追加",
                    tooltip: "ディストリビューターを追加",
                    success: "ディストリビューターが追加されました",
                },
                edit: {
                    tooltip: "ディストリビューターを編集",
                    success: "ディストリビューターが編集されました",
                },
                delete: {
                    tooltip: "ディストリビューターを削除",
                    title: "ディストリビューターを削除",
                    text: "このディストリビューターを削除してもいいですか?",
                    matchings:
                        "ディストリビューターは、以下の連結トップのタイトルで使用されています",
                    titleLink: ({ artist, title }) => `${title} の ${artist}`,
                    submit: "削除",
                    success: "ディストリビューターが削除されました",
                },
                createOrUpdate: {
                    create: {
                        title: "ディストリビューターを追加",
                        submit: "追加",
                    },
                    update: {
                        title: "ディストリビューターを編集",
                        submit: "編集",
                    },
                    cnmName: "オリジナルCNM名",
                    name: "名前",
                    type: {
                        label: "タイプ",
                        typePlaceholder: "タイプを選択",
                        distributor: "ディストリビューター",
                        topDistributor: "トップディストリビューター",
                    },
                },
                conflict:
                    "以下のCNMディストリビュータは統合トップで使用されていますが、アップロードされたファイルにはありません。:",
            },
            cypok_languages: {
                title: "Cypok 言語",
                upload_help:
                    "ファイルは、改行区切りのJSON形式を使用する必要があります",
                empty: "結果なし",
                artist: "アーティスト",
                title_column: "タイトル",
                languages: "言語",
                search: "検索...",
            },
            platforms: {
                title: "プラットフォーム",
                empty: "プラットフォーム無し",
                name: "名前",
                add: {
                    button: "追加",
                    tooltip: "プラットフォームを追加",
                    success: "プラットフォームが追加されました",
                },
                edit: {
                    tooltip: "プラットフォームを編集",
                    success: "プラットフォームが編集されました",
                },
                enable: {
                    tooltip: "プラットフォームを有効",
                    success: "プラットフォームが有効になりました",
                },
                disable: {
                    tooltip: "プラットフォームを無効",
                    success: "プラットフォームが無効になりました",
                },
                createOrUpdate: {
                    create: {
                        title: "プラットフォームを追加",
                        submit: "追加",
                    },
                    update: {
                        title: "プラットフォームを編集",
                        submit: "編集",
                    },
                    name: "名前",
                },
            },
        },
        aggregation: {
            yearSelect: {
                type: {
                    TEST: "テスト",
                    REAL: "リアル",
                },
            },
            cnmTitleList: {
                head: "CNM タイトル",
                position: "N°",
                artist: "アーティスト",
                title: "タイトル",
                platforms: "プラットフォーム",
                isrcs: "ISRC",
                duplicate: "重複",
                mixed: "ミクスト",
                streams: "ストリーム",
                empty: "結果なし",
                search: "検索...",
                filters: {
                    missingPlatforms: "欠番のプラットフォーム",
                    numberOfMatchedPlatforms: "プラットフォーム数",
                    status: {
                        placeholder: "地位",
                        none: "とも",
                        checked: "チェック済み",
                        discarded: "破棄されました",
                    },
                },
                discardButton: {
                    discard: "タイトルを破棄する",
                    recover: "タイトルを回収する",
                    commentTitle: "理由：",
                    head: "タイトルを破棄する",
                    comment: "理由",
                    submit: "ディスカード",
                },
            },
            search: {
                head: "類似の CNM タイトル",
                sameIsrc: "タイトルは共通のISRCを持つ",
                artist: "アーティスト",
                title: "タイトル",
                platforms: "プラットフォーム",
                streams: "ストリーム",
                check: "チェック済みとする",
                uncheck: "未確認とする",
                empty: "結果なし",
                form: {
                    search: "検索...",
                },
            },
            platformTitleList: {
                head: "プラットフォームタイトル",
                position: "N°",
                artist: "アーティスト",
                title: "タイトル",
                subtitle: "サブタイトル",
                isrcs: "ISRC",
                duration: "長さ",
                streams: "ストリーム",
                empty: "結果なし",
                move: "タイトルを移動する",
            },
            createButton: {
                head: "選択項目からCNMタイトルを作成",
                artist: "アーティスト",
                title: "タイトル",
                success: "作成されたタイトル",
            },
        },
        charts: {
            platform: {
                nav: "チャート　- プラットフォーム",
                year: {
                    head: "年",
                    empty: "結果なし",
                    name: "名前",
                    type: {
                        TEST: "テスト",
                        REAL: "リアル",
                    },
                },
                pf: {
                    head: "プラットフォーム",
                    empty: "結果なし",
                    name: "名前",
                    show_disabled: "無効なプラットフォームを見る",
                },
                upload: {
                    title: "チャートをアップロード",
                    file: "ファイル",
                    file_placeholder: "CSVファイルを選択",
                    browse: "ブラウズ",
                    year: "年",
                    type: {
                        label: "タイプ",
                        TEST: "テスト",
                        REAL: "リアル",
                    },
                    submit: "アップロード",
                    submitting: "アップローディング",
                    success: "チャートがアップロードされました",
                    confirm: {
                        head: "このトップをアップロードしてもよろしいですか? 次のフィールドが変更されます:",
                        difference: ({ track, modifiedFields }) =>
                            `${track.artist_display} – ${track.title} (ID ${
                                track.track_id
                            }, ランク ${track.position}): ${modifiedFields.join(
                                ", "
                            )}`,
                    },
                },
                matching: {
                    head: "マッチング",
                    empty: "結果なし",
                    search: "検索...",
                    position: "N°",
                    artist: "アーティスト",
                    title: "タイトル",
                    isrc: "ISRC",
                    cnm_artist: "CNM アーティスト",
                    cnm_title: "CNM タイトル",
                    streams: "ストリーム",
                    filters: {
                        charts: {
                            placeholder: "チャート",
                            initial: "イニシャル",
                            complement: "補足",
                        },
                    },
                },
            },
            deduplicated: {
                nav: "チャート - 複製",
                year: {
                    head: "年",
                    empty: "結果なし",
                    name: "名前",
                    type: {
                        TEST: "テスト",
                        REAL: "リアル",
                    },
                },
                pf: {
                    head: "プラットフォーム",
                    empty: "結果なし",
                    name: "名前",
                    show_disabled: "無効なプラットフォームを見る",
                },
                chart: {
                    head: "チャート",
                    empty: "結果なし",
                    search: "検索...",
                    position: "N°",
                    artist: "アーティスト",
                    title: "タイトル",
                    isrc: "ISRC",
                    streams: "ストリーム",
                },
            },
            aggregate: {
                nav: "チャート - 集計",
                year: {
                    head: "年",
                    empty: "結果なし",
                    name: "名前",
                    type: {
                        TEST: "テスト",
                        REAL: "リアル",
                    },
                },
                chart: {
                    head: "チャート",
                    empty: "結果なし",
                    search: "検索...",
                    rank: "ランク",
                    artist: "アーティスト",
                    title: "タイトル",
                    isrcs: "ISRC",
                    platforms: "プラットフォーム",
                    streams: "ストリーム",
                    export: {
                        head: "エクスポート",
                        requestForComplement: "補足のリクエスト",
                        requestForConsolidationData: "連結データのリクエスト",
                        cypok: "Cypok",
                    },
                },
            },
        },
        report: {
            types: {
                title: "タイプ",
                empty: "タイプ無し",
            },
            years: {
                title: "年",
                empty: "年無し",
                type: {
                    TEST: "テスト",
                    REAL: "リアル",
                },
            },
            progress: {
                title: "進行",
                aggregate: "集計トップ",
                upload: "アップロード",
                matching: "マッチング",
                building: "トップ1000を作成中",
                coverage: "集計トップの範囲",
                consolidation: "トップ1000の統合",
                logs: {
                    title: "ログ",
                    uploadChart: "アップロードされたトップ",
                    uploadCypok: "アップロードされたCypokデータ",
                    requestForComplement: "エクスポートされた補足のリクエスト",
                    requestForCypok:
                        "エクスポートされたCypokデータのリクエスト",
                },
                lock: {
                    title: "インディケーターをロック",
                    disabled:
                        "テストバッチからインディケーターをロックすることはできません。",
                    success: "インディケーターがロックされました",
                },
                unlock: {
                    title: "インディケーターをアンロック",
                    disabled:
                        "テストバッチからインディケーターをアンロックすることはできません。",
                    success: "インディケーターがロックされました",
                },
            },
            consolidate: {
                yearSelect: {
                    type: {
                        TEST: "テスト",
                        REAL: "リアル",
                    },
                },
                chart: {
                    head: "チャート",
                    search: "検索...",
                    empty: "結果なし",
                    rank: "N°",
                    artist: "アーティスト",
                    title: "タイトル",
                    platformSelect: {
                        aggregate: "集計チャート",
                        global: "グローバル",
                    },
                    filter: {
                        firstLetter: "1通目",
                        status: {
                            placeholder: "地位",
                            none: "とも",
                            validated: "ゆうかく",
                            discarded: "破棄されました",
                            reported: "しょさい",
                        },
                    },
                },
                edit: {
                    head: "タイトル",
                    header: {
                        platforms: "プラットフォーム",
                        openInAggregation:
                            "このタイトルをアグリゲーションインターフェースで開く",
                        artist: "アーティスト",
                        artist_warning:
                            "これらの情報全てのアーティストのタイトルで変更されます。",
                        title: "タイトル",
                        vocals: "ボーカル",
                        problems: "問題点",
                    },
                    title: {
                        label: "タイトル",
                    },
                    msArtists: {
                        label: "MS アーティスト",
                    },
                    isrcs: {
                        label: "ISRC",
                        placeholder: "ISRCを選択",
                        reference: "ISRC (MS / プラットフォーム)",
                        select: "ISRCを選択",
                        unselect: "ISRCを未選択",
                        selectAll: "全てのISRCを選択",
                    },
                    genre: {
                        label: "ジャンル",
                        placeholder: "ジャンルを選択",
                        reference: "ジャンル (MS / プラットフォーム)",
                        select: "ジャンルを選択",
                        unselect: "ジャンルを未選択",
                    },
                    leadGender: {
                        label: "リードの性別(アーティスト)",
                        placeholder: "性別を選択",
                        reference: "リードの性別(MS)",
                        select: "性別を選択",
                        unselect: "性別を未選択",
                        F: "女性",
                        M: "男性",
                        MIXED: "混合",
                    },
                    countries: {
                        label: "国籍",
                        placeholder: "アーティストの国を選択してください",
                    },
                    voice: {
                        label: "リードの性別 (ボイス)",
                        placeholder: "ボイスを選択",
                        F: "女性",
                        M: "男性",
                        MIXED: "混合",
                        INSTRUMENTAL: "インストルメンタル",
                    },
                    publicationDate: {
                        label: "発行日",
                        reference: "P発行日 (MS / プラットフォーム)",
                        select: "日付を選択",
                        unselect: "日付を未選択",
                    },
                    ageType: {
                        label: "タイプ",
                        new: "新規",
                        recurring: "循環",
                        gold: "ゴールド",
                        warning: ({ from, to, date }) =>
                            `タイプが ${from} から ${to} と ${date}に変更されました`,
                    },
                    label: {
                        label: "レーベル",
                        placeholder: "レーベルを選択",
                        create: "レーベルを作成",
                        reference: "レーベル (MS / プラットフォーム)",
                        copy: "レーベルをコピー",
                    },
                    distributor: {
                        label: "ディストリビューター",
                        placeholder: "ディストリビューターを選択",
                        create: "ディストリビューターを作成",
                        reference: "ディストリビューター (プラットフォーム)",
                        copy: "ディストリビューターをコピー",
                    },
                    cnmArtist: {
                        label: "CNM アーティスト",
                        placeholder: "CNM アーティストを選択",
                        name: "名前",
                        create: {
                            title: "アーティストを作る",
                            success: "アーティストが誕生しました",
                        },
                        update: {
                            title: "アーティストを編集する",
                            success: "アーティストが修正されました",
                        },
                    },
                    csaArtist: {
                        label: "CSA アーティスト",
                        placeholder: "CSA アーティストを選択",
                    },
                    levelOfDevelopment: {
                        label: "発展レベル",
                        confirmed: "確認済",
                        new: "新規タレント",
                    },
                    language: {
                        label: "言語",
                        placeholder: "言語を選択",
                        LyricfindReference: "言語 (Lyricfind)",
                        select: "言語を選択",
                        unselect: "言語を未選択",
                        FRENCH: "フランス",
                        INTERNATIONAL: "インターナショナル",
                        INSTRUMENTAL: "インストルメンタル",
                    },
                    platformTitles: {
                        head: "プラットフォームタイトル",
                        platform: "プラットフォーム",
                        artist: "アーティスト",
                        title: "タイトル",
                        isrcs: "ISRC",
                        duration: "長さ",
                        streams: "ストリーム",
                        empty: "結果なし",
                    },
                    reportButton: {
                        title: "報告書",
                        tooltip: "タイトルで問題を報告する",
                        head: "タイトルで問題を報告する",
                        reason: "理由",
                        submit: "報告書",
                    },
                    validate: "タイトルを確認する",
                    discard: {
                        head: "タイトルを破棄する",
                        shouldBeFalse: "廃棄されたタイトルは検証できない",
                    },
                    report: {
                        head: "タイトルを報告する",
                        shouldBeFalse:
                            "報告されたタイトルを検証することはできません",
                    },
                    discardReason: {
                        head: "理由",
                        placeholder: "理由を選ぶ",
                        required: "タイトルが理由なく破棄されることはない",
                    },
                    reportReason: "理由",
                    success: "タイトルが変更されました",
                    error: "情報を保存中にエラーが発生しました",
                },
            },
            indicators: {
                title: "インディケーター",
                top_titles: "トップタイトル",
                top_artists: "トップアーティスト",
                indicators: "インディケーター",
            },
        },
    },
    charts: {
        breadcrumb: "チャート",
        display: {
            breadcrumb: "ディスプレイ",
        },
        translation: {
            breadcrumb: "翻訳",
        },
    },
    partner_tops: {
        partners: {
            title: "パートナー",
            empty: "パートナー無し",
        },
        dates: {
            title: "日付",
            empty: "日付無し",
        },
        tops: {
            title: "トップ",
            empty: "トップ無し",
        },
        artists: {
            title: "アーティスト",
            empty: "アーティスト無し",
            rank: "ランク",
            name: "名前",
            matchings: "マッチ",
            editMatchings: "マッチを編集",
            status: "ステータス",
            biographies: "バイオグラフィー",
            biographies_fr: "フランス語",
            biographies_en: "英語",
            pictures: "画像",
            discarded: ({ date, user, comment }) =>
                `${date} に ${user} を破棄 - "${comment}"`,
            filters: {
                max_rank: {
                    title: "ランク",
                    top: (n) => `トップ ${n}`,
                },
                matched: {
                    title: "マッチ",
                    yes: "マッチ済",
                    no: "マッチ無",
                },
                discarded: {
                    title: "破棄済",
                    yes: "破棄済",
                    no: "破棄無",
                },
                bio_en: {
                    title: "英語バイオグラフィー",
                    yes: "英語バイオグラフィー付き",
                    no: "英語バイオグラフィー無し",
                },
                bio_fr: {
                    title: "フランス語バイオグラフィー",
                    yes: "フランス語バイオグラフィー付き",
                    no: "フランス語バイオグラフィー無し",
                },
                pictures: {
                    title: "画像",
                    valid: "有効な画像",
                    improve: "改善する画像",
                    missing: "画像がありません",
                },
            },
            matching: {
                create: {
                    success: "マッチが作成されました",
                    duplicate: "マッチがすでに存在します",
                    error: (message) =>
                        `マッチを作成中にエラーが発生しました: ${message}`,
                },
                update: {
                    success: "マッチを編集",
                    not_found:
                        "マッチを編集中にエラーが発生しました: マッチはもう存在しません",
                    duplicate:
                        "マッチを編集中にエラーが発生しました: マッチがすでに存在します",
                    error: (message) =>
                        `マッチを編集中にエラーが発生しました: ${message}`,
                },
                delete: {
                    success: "マッチを削除",
                    not_found:
                        "マッチを削除中にエラーが発生しました: マッチはもう存在しません",
                    error: (message) =>
                        `マッチを削除中にエラーが発生しました: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "破棄",
                },
                modal: {
                    title: "間違いないですか？",
                    text: "このアーティストを破棄していいですか？",
                    placeholder: "コメント",
                },
                success: "アーティストを破棄しました",
                duplicate:
                    "マッチを破棄中にエラーが発生しました: アーティストはすでに破棄されています",
                error: (message) =>
                    `マッチを破棄中にエラーが発生しました: ${message}`,
            },
            recover: {
                button: {
                    title: "復元",
                },
                modal: {
                    title: "間違いないですか？",
                    text: "このアーティストを復元しますか？",
                },
                success: "アーティストが復元されました",
                not_found:
                    "マッチを復元中にエラーが発生しました: アーティストは破棄されていません",
                error: (message) =>
                    `マッチを復元中にエラーが発生しました: ${message}`,
            },
        },
        artistsV3: {
            title: "アーティスト",
            empty: "アーティスト無し",
            rank: "ランク",
            artist: "アーティスト",
            ms_artist: "MS アーティスト",
            discography_score: "アルバムがマッチされました",
            biography_en: "英語バイオグラフィー",
            biography_fr: "フランス語バイオグラフィー",
            curation: "作成",
            pictures: "画像",
            discarded_on: (date) => ` ${date}に破棄`,
            filters: {
                max_rank: {
                    title: "ランク",
                    top: (n) => `トップ ${n}`,
                },
                matched: {
                    title: "マッチ",
                    yes: "マッチ付き",
                    no: "マッチ無し",
                },
                discarded: {
                    title: "破棄済",
                    yes: "破棄済",
                    no: "破棄無し",
                },
                bio_en: {
                    title: "英語バイオグラフィー",
                    yes: "英語バイオグラフィー付き",
                    no: "英語バイオグラフィー無し",
                },
                bio_fr: {
                    title: "フランス語バイオグラフィー",
                    yes: "フランス語バイオグラフィー付き",
                    no: "フランス語バイオグラフィー無し",
                },
                curation: {
                    title: "キューレーション",
                    yes: "キューレーション付き",
                    no: "キューレーション無し",
                },
                pictures: {
                    title: "画像",
                    yes: "画像付き",
                    no: "画像無し",
                },
            },
            deezer: {
                market: "マーケット",
                musicbrainz: "MusicBrainz",
                facebook: "フェイスブック",
                filters: {
                    market: {
                        title: "マーケット",
                    },
                },
                pictures: {
                    head: "Deezer",
                    backstage: "バックステージ",
                    other: "その他",
                    none: "無し",
                },
            },
            napster: {
                country: "国",
                filters: {
                    country: {
                        title: "国",
                    },
                },
            },
            matching: {
                create: {
                    success: "マッチが作成されました",
                    duplicate: "マッチがすでに存在します",
                    error: (message) =>
                        `マッチを作成中にエラーが発生しました: ${message}`,
                },
                update: {
                    success: "マッチが編集されました",
                    not_found:
                        "マッチを編集中にエラーが発生しました: もうマッチが存在しません",
                    duplicate:
                        "マッチを編集中にエラーが発生しました: すでにマッチが存在します",
                    error: (message) =>
                        `マッチを編集中にエラーが発生しました: ${message}`,
                },
                delete: {
                    success: "マッチが削除されました",
                    not_found:
                        "マッチを削除中にエラーが発生しました: もうマッチが存在しません",
                    error: (message) =>
                        `マッチを削除中にエラーが発生しました: ${message}`,
                },
            },
            discard: {
                button: {
                    title: "破棄",
                },
                modal: {
                    title: "間違いないですか？",
                    text: "このアーティストを破棄してもいいですか？",
                    placeholder: "コメント",
                },
                success: "アーティストが破棄されました",
                duplicate:
                    "アーティストを破棄中にエラーが発生しました: アーティストはすでに破棄されています",
                error: (message) =>
                    `アーティストを破棄中にエラーが発生しました: ${message}`,
            },
            recover: {
                button: {
                    title: "復元",
                },
                modal: {
                    title: "間違いないですか？",
                    text: "このアーティストを復元しますか？",
                },
                success: "アーティストが復元されました",
                not_found:
                    "アーティストを復元中にエラーが発生しました: アーティストは破棄されていません",
                error: (message) =>
                    `マッチを復元中にエラーが発生しました: ${message}`,
            },
        },
        pictures: {
            none: "無し",
            getty: "ゲッティ",
            curation: "キューレーション",
            label: "レーベル",
            backstage: "Deezer - バックステージ",
            other: "Deezer - その他",
            last_label_creation: "最終レーベル画像追加日",
            last_getty_creation: "最終ゲッティ画像追加日",
            last_getty_date: "最新ゲッティ写真撮影日",
            last_check_date: "最終チェック日",
        },
    },
    pictures: {
        breadcrumb: "画像",
        label: {
            gallery: {
                breadcrumb: "クライアント利用可能",
                empty: "結果なし",
                head: (label) => `レーベル画像: ${label}`,
                search: "アーティストを検索",
                artist: "アーティスト",
                action: "実行",
                expired: "期限切れ画像",
                takedownLabel: "削除日",
                add: "画像を追加",
            },
            status: {
                breadcrumb: "保留中 / トラッキング",
                empty: "結果なし",
                head: (label) => `レーベル画像: ${label}`,
                search: "アーティストを検索",
                picture: "画像",
                artist: "アーティスト",
                status: "ステータス",
                filterStatusLabel: "トラッキングを見る:",
                historic: "履歴",
                statusChange: "ステータス変更",
                createdAt: "登録日",
                formatedStatus: (status) => status.replaceAll("_", " "),
                definition: {
                    AWAITING:
                        "画像はアカウント内に入っておりすぐに処理されます",
                    AWAITING_FOR_PROCESSING:
                        "画像は受理され、文書化のために保留中です",
                    DOCUMENTED:
                        "画像は検証プロセスに通り利用できるようになりました",
                    REJECTED:
                        "写真はミュージックストーリーの基準に一致しないため検証できません",
                },
            },
            validation: {
                breadcrumb: "処理される画像",
                empty: "結果なし",
                validationHead: "検証",
                processingHead: "処理中",
                search: "アーティストまたはレーベルを検索",
                picture: "画像",
                artist: "アーティスト",
                label: "レーベル",
                title: "タイトル",
                copyright: "著作権",
                date_takedown: "削除日",
                action: "実行",
                validate: {
                    title: "検証",
                    alert: {
                        title: "間違いないですか？",
                        text: "この画像を検証してもいいですか？",
                    },
                    success: "画像は検証されました",
                    error: (message) =>
                        `この画像を検証中にエラーが発生しました: ${message}`,
                },
                reject: {
                    title: "拒否",
                    alert: {
                        title: "間違いないですか？",
                        text: "この画像を拒否してもいいですか？",
                        commentTitle: "コメントを追加",
                    },
                    success: "画像は拒否されました",
                    error: (message) =>
                        `この画像を拒否中にエラーが発生しました: ${message}`,
                },
            },
            rejected: {
                breadcrumb: "画像は拒否されました",
                empty: "結果なし",
                head: "画像は拒否されました",
                search: "アーティストまたはレーベルを検索",
                picture: "画像",
                artist: "アーティスト",
                label: "レーベル",
                title: "タイトル",
                copyright: "著作権",
                date_takedown: "削除日",
                comment: "コメント",
                action: "実行",
                retrieve: {
                    title: "取得",
                    alert: {
                        title: "間違いないですか？",
                        text: "この画像を取得してもいいですか？",
                    },
                    success: "画像を取得しました",
                    error: (message) =>
                        `この画像を取得中にエラーが発生しました: ${message}`,
                },
                edit: {
                    title: "コメントを更新",
                    success: "コメントが更新されました",
                    error: (message) =>
                        `このコメントを更新中にエラーが発生しました: ${message}`,
                },
            },
        },
    },
    labelPromo: {
        breadcrumb: "レーベル",
        manage: {
            breadcrumb: "管理",
            label: {
                head: "レーベル",
                empty: "結果なし",
                id: "ID",
                name: "名前",
                created_at: "作成日",
                updated_at: "編集日",
                status: "ステータス",
                action: "実行",
                search: "検索...",
            },
            show: {
                title: "ユーザーの視覚化",
                empty: "関連付けられたユーザーはいません",
            },
            create: {
                title: "レーベルを作成",
                success: "レーベルが作成されました",
                error: (message) =>
                    `このレーベルを作成中にエラーが発生しました: ${message}`,
            },
            update: {
                title: "レーベルを更新",
                success: "レーベルが更新されました",
                error: (message) =>
                    `このレーベルを更新中にエラーが発生しました ${message}`,
            },
            delete: {
                title: "レーベルを削除",
                alert: {
                    title: "間違いないですか？",
                    text: "このレーベルを削除してもいいですか？",
                },
                success: "レーベルが削除されました",
                error: (message) =>
                    `このレーベルを削除中にエラーが発生しました: ${message}`,
            },
        },
    },
    artistTitle: {
        breadcrumb: "アーティスト-タイトル",
        searchForm: {
            artist: "アーティスト",
            title: "タイトル",
            search: "検索",
        },
        resultsList: {
            count: (count) => `${count} 結果`,
            isrc: (isrc) => (isrc ? `ISRC : ${isrc}` : "ISRC無し"),
            recordingId: (id) => `Recording id : ${id}`,
            artist: {
                head: "アーティスト",
                picture: "アーティスト画像",
                id: (id) => `アーティストID : ${id}`,
            },
            album: {
                head: "アルバム",
                picture: "アルバム画像",
                id: (id) => `アルバムIDID : ${id}`,
            },
        },
    },
    recommendation: {
        form: {
            positive: "フィールドは正でなければなりません",
            min: "フィールドは0未満であってはいけません",
            max: "フィールドは100を超えてはいけません",
            select_options: {
                low: "低い（0-0.3333）",
                medium: "中間（0.3333-0.6666）",
                high: "高い（0.6666-1）",
            },
            select_options_bpm: {
                low: "低い（0-76）",
                medium: "中間（76-160）",
                high: "高い（160以上）",
            },
        },
        breadcrumb: "拡大推奨",
        results: "結果",
        empty: "結果なし",
        search: "検索...",
        ms_track_ids: "MS トラック ID",
        ms_track_id: "MS トラック ID",
        ms_artist_ids: "MS アーティスト ID",
        min_accuracy: "最小精度",
        fa_bpm: "Bpm",
        fa_arousal: "覚醒",
        fa_moods: "ムード",
        fa_valence: "ヴァレンス",
        fa_intensity: "強度",
        year_range: "年",
        fa_descriptor: "記述子",
        name: "名前",
        title: "タイトル",
        subtitle: "サブタイトル",
        genre: "ジャンル",
        original_album: "アルバム",
        original_release_date: "リリース日",
        artists: "アーティスト",
        mbid: "Mbid",
        match: "一致",
        popularity: "人気",
    },
    data_external: {
        breadcrumb: "外部日",
        internationals_charts: {
            breadcrumb: "インターナショナルチャート",
            charts: {
                nav: "チャート",
                empty: "結果なし",
                id: "ID",
                name: "名前",
                positions: "ポジションの数",
                treatments: "処理の範囲",
                search: "検索...",
            },
            stats: {
                nav: "統計",
                empty: "結果なし",
                search: "検索...",
                title: "タイトル",
                country: "国",
                start_date: "日付",
                treatment: "マッチ済",
                matched_simple: "シンプル",
                creation: "作成",
                nb_discarded: "破棄",
                nb_forgot: "忘れ",
            },
            coverage: {
                nav: "情報範囲",
                empty: "結果なし",
                search: "検索...",
                simple: "シンプルマッチ",
                creation: "作成概要",
                discarded: "破棄済 - チェックされただがプロセスなし",
                forgot: "破棄済 - 忘れられた",
                title: "タイトル",
                country: "国",
                area: "エリア",
                date: "日付",
                rank: "ランク",
                artist: "アーティスト",
                album_track: "アルバム/トラック",
                ms_artist: "MS Aアーティスト",
                comment: "コメント",
                user: "ユーザー",
            },
        },
        deezer_top_songs: {
            breadcrumb: "Deezer - トップソング",
            stats: {
                nav: "統計",
                empty: "結果なし",
                date: "日付",
                country: "国",
                treatment: "範囲",
                nb_match_simple_ms: "シンプル MS",
                nb_match_creation_ms: "作成 Ms",
                nb_discard_ms: "破棄　MS",
                nb_match_lf: "マッチ LF",
                nb_request_lf: "リクエスト LF",
                nb_forgot_lf: "忘れ LF",
                nb_match_lf_cas1: "マッチ カス 1",
                nb_match_lf_cas23_with_request: "リクエスト　カス 2/3",
                nb_match_lf_cas23_without_request: "リクエスト無し　カス 2/3",
                nb_forgot_ms_lf: "忘れ MS + LF",
            },
            coverage: {
                nav: "情報範囲",
                empty: "結果なし",
                search: "検索...",
                simple: "シンプルマッチ",
                creation: "作成概要",
                new_lyrics: "歌詞マッチ",
                discarded_full: "破棄",
                forgot_full: "全て忘れ",
                forgot_lf: "忘れ LF",
                forgot_2_3: "忘れ 2/3",
                date: "日付",
                country: "国",
                id_deezer: "Deezer ID",
                artist: "アーティスト",
                album: "アルバム",
                song: "曲",
                ms_artist: "MS アーティス",
                ms_album: "MS アルバム",
                lf_composition: "楽曲",
                comment: "コメント",
                user: "ユーザー",
            },
        },
        universal_artist: {
            breadcrumb: "ユニバーサル",
            stats: {
                nav: "統計",
                empty: "結果なし",
                date: "日",
                total: "グローバル",
                matched_total: "週",
                matched_simple: "シンプル",
                matched_creation: "作成",
                discarded: "破棄",
            },
            coverage: {
                nav: "カバレッジ情報",
                empty: "結果なし",
                search: "検索...",
                simple: "シンプル",
                creation: "作成",
                discarded: "破棄",
                id: "ID",
                name: "名前",
                ms_artist: "MS アーティスト",
                comment: "コメント",
                user: "ユーザー",
            },
        },
    },
    api: {
        breadcrumb: "API",
        monitoring: {
            breadcrumb: "モニタリング",
            token: "APIトークン",
            status: {
                head: "ステータス",
                remaining: "残り",
                limit: "リミット",
                retry: "再試行する時間後",
                retry_description:
                    "残りのクエリが0に達すると、リセット日が表示されます。これは新しいクエリを行うことができる日付を示しています。",
                status: "ステータス",
            },
            historic: {
                head: "歴史的な",
                date: "日付",
                requests: "リクエスト",
            },
        },
    },
    moodmedia: {
        breadcrumb: "ムードメディア",
        search: {
            breadcrumb: "検索",
            results: "結果",
            empty: "結果なし",
            title: "タイトル",
            artist: "アーティスト",
            id: "ID",
            original_release_date: "リリース日",
            ms_album_title: "アルバムタイトル",
            name: "名前",
            subtitle: "サブタイトル",
            artists: "アーティスト",
            mbid: "MDID",
            match: "マッチ",
            popularity: "人気度",
            link: "WS2",
        },
        recommendation: {
            form: {
                error: "MSフィールドとMMフィールドを同時に入力することはできません",
                positive: "フィールドは正でなければならない",
                min: "フィールドは0未満であってはならない",
                max: "フィールドは100以上であってはならない",
            },
            breadcrumb: "おすすめ",
            results: "結果",
            empty: "結果なし",
            search: "検索...",
            ms_track_ids: "MS トラックID",
            ms_track_id: "MS トラックID",
            ms_artist_ids: "MS アーティストID",
            mm_track_ids: "MM トラックID",
            mm_track_id: "MM トラックID",
            filter_no: "フィルター無し",
            catalog: "カタログ",
            min_accuracy: "最小精度",
            name: "名前",
            title: "タイトル",
            subtitle: "サブタイトル",
            genre: "ジャンル",
            original_album: "アルバム",
            original_release_date: "リリース日",
            artists: "アーティスト",
            mbid: "MBID",
            match: "マッチ",
            popularity: "人気度",
        },
    },
    snep: {
        file: {
            breadcrumb: "SNEPファイルの管理",
            fileList: {
                head: "ファイル",
                search: "ID／タイプ／日付",
                empty: "ファイルなし",
                id: "ID",
                name: "タイプ",
                period: "期間",
                date: "日付",
                imported: "輸入品",
                exportedSnep: "エクスポートされたSNEP",
                exportedYadeck: "エクスポートされたYadeck",
                upload: {
                    button: "追加",
                    head: "SNEPファイルを追加する",
                    file: "クリックすると、コンピュータ上のファイルを選択できます",
                    success: "ファイルが追加されました",
                },
                import: {
                    head: "SNEPファイルをインポートする",
                    name: "ファイル",
                    startDate: "スタート",
                    endDate: "終了",
                    chart: {
                        label: "チャート",
                        placeholder: "チャートを選択する",
                        album: "アルバム",
                        track: "トラック",
                    },
                    success: "ファイルがインポートされました",
                },
                export: {
                    pending: "ファイルの書き出し...",
                    success: "ファイルがエクスポートされました",
                },
                clean: {
                    title: "エクスポートを削除する",
                    success: "エクスポートは削除されました",
                },
                delete: {
                    title: "ファイルを削除する",
                    success: "ファイルが削除されました",
                },
            },
        },
    },
    matching: {
        tops: {
            breadcrumb: "マッチ",
        },
        artist: {
            partner: {
                head: "パートナー",
                name: "	名前",
            },
            list: {
                head: "リスティング",
                empty: "ノーリスティング",
                date: "#",
                name: "	名前",
                matched: "マッチ済",
                completed: "完了済",
            },
            artist: {
                head: "アーティスト",
                empty: "アーティストなし",
                id: "#",
                name: "名前",
                firstname: "名",
                lastname: "ラストネーム",
                complementary: "補完的",
                discography: "ディスコグラフィー",
                spotify: "スポティファイ",
                musicbrainz: "ミュージックブレインズ",
                max_created_at: "日付",
                complementary: "補足",
                type: "タイプ",
                getty_picture: "ゲッティ画像",
                picture: (nb) => `${nb}枚`,
                artist: "アーティスト MUSIC-STORY",
                action: "実行",
                checked: "確認済",
                edit_matchings: "マッチを編集",
                match: {
                    success: "マッチが作成されました",
                    error: (message) =>
                        `マッチを作成中にエラーが発生しました: ${message}`,
                },
                unmatch: {
                    success: "マッチを削除",
                    error: (message) =>
                        `マッチを削除中にエラーが発生しました: ${message}`,
                },
                mark: {
                    button: {
                        title: "’スキップ’としてマーク",
                    },
                    modal: {
                        title: "間違いないですか？",
                        text: "あなたはこのアーティストをスキップしたいですか？",
                        placeholder: "コメント",
                    },
                    success: "アーティストを破棄しました",
                    error: (message) =>
                        `マッチを破棄中にエラーが発生しました: ${message}`,
                },
                unmark: {
                    button: {
                        title: "’スキップしない’としてマーク",
                    },
                    success: "アーティストが復元されました",
                    error: (message) =>
                        `マッチを復元中にエラーが発生しました: ${message}`,
                },
                check: {
                    button: {
                        title: "’チェック済’としてマーク",
                    },
                    success: "アーティストチェック済み",
                    error: (message) =>
                        `アーティストチェック中のエラー: ${message}`,
                },
                artistCreated: {
                    button: {
                        title: "アーティストが制作した」とマークする",
                    },
                    success: "作成者としてマークされたアーティスト",
                    error: (message) =>
                        `アーティストの採点中のエラー : ${message}`,
                },
                unartistCreated: {
                    button: {
                        title: "アーティストが作成されていません",
                    },
                    success: "未作成とマークされたアーティスト",
                    error: (message) =>
                        `アーティストのマークを外す際のエラー : ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "マッチ済",
                        true: "マッチ済",
                        false: "マッチ無",
                    },
                    completed: {
                        placeholder: "完了済",
                        true: "完了済",
                        false: "未完了",
                    },
                    checked: {
                        placeholder: "確認済",
                        true: "確認済",
                        false: "未確認",
                    },
                    skiped: {
                        placeholder: "スキップ",
                        true: "スキップ",
                        false: "スキップしない",
                    },
                },
                legend: {
                    title: "レジェンド",
                    completed: "完了済",
                    matched: "マッチ済",
                    skiped: "スキップ",
                    notAutoMatched: "ノットマッチオート（重複）",
                    error: "エラー",
                },
            },
        },
        album: {
            partner: {
                head: "パートナー",
                name: "	名前",
            },
            list: {
                head: "リスティング",
                empty: "ノーリスティング",
                date: "#",
                name: "	名前",
                matched: "マッチ済",
            },
            album: {
                head: "アーティスト",
                empty: "アーティストなし",
                rank: "ランク",
                cover: "カバー",
                id: "#",
                title: "タイトル",
                snep_artist: "アーティスト SNEP",
                artist: "アーティスト MUSIC-STORY",
                album: "アルバム MUSIC-STORY",
                spotify_album: "アルバム SPOTIFY",
                fnac_album: "アルバム FNAC",
                match: {
                    success: "マッチが作成されました",
                    error: (message) =>
                        `マッチを作成中にエラーが発生しました: ${message}`,
                },
                unmatch: {
                    success: "マッチを削除",
                    error: (message) =>
                        `マッチを削除中にエラーが発生しました: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "マッチ済",
                        true: "マッチ済",
                        false: "マッチ無",
                    },
                },
                legend: {
                    title: "レジェンド",
                    matched: "マッチ済",
                    partially_matched: "完成",
                },
            },
        },
        track: {
            partner: {
                head: "パートナー",
                name: "	名前",
            },
            list: {
                head: "リスティング",
                empty: "ノーリスティング",
                date: "#",
                name: "	名前",
                matched: "マッチ済",
                progress: "進捗状況",
            },
            track: {
                head: "トラック",
                empty: "トラックなし",
                rank: "ランク",
                cover: "カバー",
                id: "#",
                title: "タイトル",
                snep_artist: "アーティスト SNEP",
                artist: "アーティスト MUSIC-STORY",
                recording: "トラック MUSIC-STORY",
                duration: "長さ",
                artist_album: "アーティスト - アルバム",
                case: "場合",
                lyricfind: "トラック LYRICFIND",
                actions: "行動",
                spotify_track: "トラック SPOTIFY",
                fnac_album: "アルバム FNAC",
                match: {
                    success: "マッチが作成されました",
                    error: (message) =>
                        `マッチを作成中にエラーが発生しました: ${message}`,
                },
                unmatch: {
                    success: "マッチを削除",
                    error: (message) =>
                        `マッチを削除中にエラーが発生しました: ${message}`,
                },
                request: {
                    button: {
                        enabled: "歌詞リクエストをLyricfindに送信する",
                        disabled: "Lyricfindにリクエストを提出",
                    },
                    success: "Lyricfindにリクエストを提出",
                    error: (message) => `リクエスト送信時のエラー: ${message}`,
                },
                discard: {
                    button: {
                        title: "破棄",
                    },
                    modal: {
                        title: "間違いないですか？",
                        text: "このアーティストを破棄してもいいですか？",
                        placeholder: "コメント",
                    },
                    success: "アーティストが破棄されました",
                    error: (message) =>
                        `アーティストを破棄中にエラーが発生しました: ${message}`,
                },
                recover: {
                    button: {
                        title: "復元",
                    },
                    success: "アーティストが復元されました",
                    error: (message) =>
                        `マッチを復元中にエラーが発生しました: ${message}`,
                },
                filter: {
                    matched: {
                        placeholder: "マッチ済",
                        true: "マッチ済",
                        false: "マッチ無",
                    },
                    discarded: {
                        placeholder: "破棄済",
                        true: "破棄済",
                        false: "破棄無",
                    },
                },
                legend: {
                    title: "レジェンド",
                    matched: "マッチ済",
                    completed: "完成",
                    partially_matched: "完成",
                    discarded: "破棄済",
                },
            },
        },
    },
    profile: {
        personal_details: {
            title: "個人情報",
            breadcrumb: "プロフィール",
            head: "メイン情報",
            account_type: {
                title: "アカウントタイプ",
                music_service: "Music service",
                cmo: "CMO",
                label_distributor: "Label / Distributor",
                artist_manager: "Artist / Manager",
            },
            firstname: "ファーストネーム",
            lastname: "ラストネーム",
            email: "メール",
            company: "会社",
            label: "レーベル",
            artist: "Artist",
            role: {
                title: "役割",
                music_editor: "Music editor",
                marketing: "Marketing",
                catalog_management: "Catalog Management",
                product_management: "Product Management",
                tech: "Tech",
                executive: "Executive",
                other: "Other",
                promotion: "Promotion",
                business_development: "Business development",
                a_and_r: "A&R",
                supply_chain: "Supply chain",
                analytics: "Analytics",
            },
            describe: "説明する",
            language: "言語",
            expires_at: "有効期限",
            token: "APIトークン",
            success: "情報が保存されました",
        },
        change_password: {
            title: "パスワード変更",
            old_password: "以前のパスワード",
            new_password: "新しいパスワード",
            confirm_password: "パスワードを認証する",
            success: "パスワードが更新されました",
        },
    },
    search: {
        breadcrumb: "検索",
        placeholder: "アーティスト / アルバム / トラック",
        all: "全て",
        artists: "アーティスト",
        albums: "アルバム",
        recordings: "トラック",
        empty: "結果なし",
        more: "もっと見る",
        artistsData: {
            head: "アーティスト",
            cover: "カバー",
            id: "ID",
            name: "名前",
            popularity: "人気",
            peak: "人気のピーク",
            complementary: "補足説明",
            type: "タイプ",
            genre: "ジャンル",
            country: "国",
            role: "役割",
            actions: "実行",
        },
        albumsData: {
            head: "アルバム",
            cover: "カバー",
            id: "ID",
            title: "タイトル",
            artist: "アーティスト",
            type: {
                live: "ライブ",
                remix: "リミックス",
                compilation: "コンピレーション",
                karaoke: "カラオケ",
                ring: "リング",
                other: "その他",
                original: "オリジナル",
            },
            format: {
                album: "アルバム",
                ep: "ミニアルバム(EP)",
                single: "シングル",
                mixtape: "ミックステープ",
                mcd: "マキシ",
            },
            genre: "ジャンル",
            release_date: "リリース日",
            label: "レーベル",
            actions: "実行",
        },
        recordingsData: {
            head: "トラック",
            cover: "カバー",
            id: "ID",
            title: "タイトル",
            subtitle: "サブタイトル",
            popularity: "人気",
            artist: "アーティスト",
            duration: "長さ",
            genre: "ジャンル",
            release_date: "リリース日",
            complementary: "関連コンテンツ",
            has_credits: "クレジット",
            has_lyric: "歌詞",
            has_audio: "オーディオディスクリプション",
            actions: "実行",
        },
    },
    base: {
        user: {
            account: "プロフィール",
            logout: "ログアウト",
        },
        nav: {
            home: "ホーム",
            search: "検索",
            title: {
                partners: "パートナー",
                admin: "管理",
            },
            apple: {
                title: "アップル",
                sample: {
                    title: "サンプル",
                    assessment: "課題",
                    management: "管理",
                    report: "レポート",
                    guideline: "ガイドライン",
                },
            },
            documentation: {
                title: "書類",
            },
            data: {
                title: "データ",
                artists: {
                    title: "アーティスト",
                    search: "詳細検索",
                    create: "作成",
                    followup: "トラッキングの作成/削除",
                },
                roles: "役割",
                country: "国",
                genres: "ジャンル",
                charts: {
                    title: "チャート",
                    display: "表示",
                    translation: "翻訳",
                },
                labelPromo: "レーベル",
                data_external: {
                    title: "外部日",
                    internationals_charts: "インターナショナルチャート",
                    deezer_top_songs: "Deezer - トップソング",
                    universal_artist: "ユニバーサル",
                },
                misc_entity: "ショー",
                api: {
                    title: "API",
                    getting_started: "スタート",
                    api_reference: "API参照",
                    monitoring: "モニタリング",
                    status: "ステータス",
                },
            },
            tools: {
                title: "ツール",
                artistTitle: "アーティスト-タイトル",
                movePicture: "画像を移動",
            },
            plannings: {
                title: "プラン",
                overview: "概要",
                manage: "プラン管理",
                picture: "画像履歴",
                billing: "請求",
                statistic: "統計",
            },
            pictures: {
                title: "画像",

                label: {
                    gallery: "クライアント利用可",
                    status: "保留中 / トラッキング",
                    validation: "画像検証予定",
                    rejected: "拒否された画像",
                },
                curation: {
                    followup: "キューレーションフォローアップ",
                },
                getty: {
                    followup: "ゲッティフォローアップ",
                },
            },
            matching: {
                title: "マッチング",
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                tops: "チャート",
                lyricsSample: "歌詞サンプル",
                partner_tops: "トップアーティスト",
            },
            snep: {
                title: "SNEP",
                file: "管理トップ",
                chart: "チャート管理",
            },
            cnm: {
                title: "CNM",
                repositories: "リポジトリ",
                aggregation: "アグリゲーション",
                consolidation: "混載",
                charts: "チャート",
                report: "レポート",
            },
            moodmedia: {
                title: "ムードメディア",
                search: "検索",
                recommendation: "おすすめ",
            },
            admin: {
                users: {
                    title: "ユーザー",
                    user: "ユーザー管理",
                    author: "作家管理",
                },
                rights: {
                    title: "権利",
                    role: "役割管理",
                    right: "権利管理",
                    group: "グループ管理",
                },
            },
        },
    },
    common: {
        explicit: {
            long: "明示的",
            short: "E",
        },
        multiSearch: {
            placeholder: "アーティスト / アルバム / トラック",
            explanation: () => (
                <>
                    アーティスト、アルバム、録音を検索して、APIやデータフローで利用可能な全ての関連メタデータを探索してください:
                    <ul className="mb-0">
                        <li>
                            アーティスト:
                            ISNI、伝記、画像、人気度、関連アーティスト...
                        </li>
                        <li>
                            アルバム:
                            UPCメタデータ、正規アルバム、ジャンル、オリジナルリリース日...
                        </li>
                        <li>
                            録音:
                            ISRCメタデータ、クレジット、歌詞、オーディオ説明...
                        </li>
                    </ul>
                </>
            ),
            search: "Music-Story検索",
            artist: "アーティスト",
            album: "アルバム",
            type: {
                live: "ライブ",
                remix: "リミックス",
                compilation: "コンピレーション",
                karaoke: "カラオケ",
                ring: "リング",
                other: "その他",
                original: "オリジナル",
            },
            format: {
                album: "アルバム",
                ep: "ミニアルバム(EP)",
                single: "シングル",
                mixtape: "ミックステープ",
                mcd: "マキシ",
            },
            recording: "トラック",
            has_credits: "クレジット",
            has_lyric: "歌詞",
            has_audio: "オーディオディスクリプション",
            more: "もっと見る",
            empty: "結果なし",
        },
        matching: {
            status: {
                not_found: "見つかりません",
                matched: "マッチ済",
                mixed: "混合バイオグラフィー",
                duplicates: "重複",
                unmatched: "アンマッチ",
            },
        },
        partners: {
            "7D": "7デジタル",
            AZ: "アマゾン",
            DG: "ディスコグス",
            DZ: "ディーザー",
            IT: "アイチューンズ",
            MB: "ミュージックブレインズ",
            NA: "ナップスター",
            PR: "プライスミニスター",
            QB: "クーバス",
            UN: "ユニバーサル",
            SP: "スポティファイ",
            CJ: "CDジャーナル",
            LM: "LineMusic",
        },
        apiDownloadButton: {
            pending: "ファイルを作成",
            success: "ファイルが作成されました",
        },
        editMatchingsTable: {
            empty: "候補なし",
            id: "ID",
            name: "名前",
            type: {
                title: "タイプ",
                discarded: "破棄済",
                valid: "手動で検証",
                invalid: "手動で未検証",
                matchedByName: "によるマッチ名前",
                matchedByDiscography: "ディスコグラフィーによるマッチ",
            },
            representativity: "代表",
            actions: {
                validate: "マッチを検証",
                cancelValidation: "手動検証をキャンセル",
                invalidate: "マッチを無効",
                cancelInvalidation: "手動無効化をキャンセル",
            },
        },
        editMatchingsModal: {
            status: "ステータス:",
            matched: "マッチ済",
            not_found: "マッチ無し",
        },
        pictureCheckButton: {
            title: "チェック",
            confirmModal: {
                title: "間違いないですか？",
                text: "このアーティストの画像を検証してもいいですか?",
            },
            commentModal: {
                title: "コメントを追加",
                success: "コメントが追加されました",
                error: (message) =>
                    `コメントを追加中にエラーが発生しました: ${message}`,
            },
        },
        pictureTagsModal: {
            title: "元画像のタグを編集する",
            titleVersion: "正方形の画像タグを編集する",
            metas: "メタ",
            tags: "タグ",
            description: {
                2: () => (
                    <>
                        <strong>屋内: </strong>
                        他のカテゴリーに当てはまらない室内写真
                        <br />
                        <strong>屋外: </strong>
                        他のカテゴリーに当てはまらない屋外の写真
                        <br />
                        <strong>ステージ: </strong>
                        ショー中に撮影されたステージ上の写真
                    </>
                ),
                7: () => (
                    <>
                        <strong>1:</strong> Image size greater than or equal to
                        2500px.
                        <br />
                        <strong>2:</strong> Image size between 1500px (included)
                        and 2500px (excluded).
                        <br />
                        <strong>3:</strong> Image size between 800px (included)
                        and 1500px (excluded).
                    </>
                ),
                4: () => (
                    <>
                        写真が撮影された期間。正確な年代を特定することは困難であるため、5年間の期間を使用した。その目的は、アーティストのキャリアのどの時点で撮影されたかを知るために、写真を時間軸上に配置することである。
                        写真の正確な日付が判明した場合は、日付タグも実装されている。ゲッティの写真については、画像の
                        "メタ
                        "ディスクリプションにその記述があるかどうかを確認してください。
                    </>
                ),
                5: () => (
                    <>
                        <strong>被写体: </strong>
                        アーティストが一人しか写っていない場合
                        <br />
                        <strong>グループ: </strong>
                        数人が写っているが、フルバンドではない場合。
                        <br />
                        <strong>コンプリートグループ: </strong>
                        フルバンドが写っている場合
                    </>
                ),
                6: () => (
                    <>
                        <strong>ポートレート: </strong>髪から肩まで
                        <br />
                        <strong>ハーフレングスポートレート: </strong>レート
                        髪から腰まで
                        <br />
                        <strong>全身写真: </strong>全身
                    </>
                ),
            },
            shotDate: "撮影日",
            msArtists: "MS アーティスト",
            msArtistsPlaceholder: "アーティストを選択",
            open_tags: {
                title: "Open tags",
                placeholder: "Enter open tags",
            },
            tags_getty: "Tags Getty",
            success: "タグを更新しました",
            alert: {
                title: "よろしいですか？",
                text: "このウィンドウを閉じると、入力したデータは失われます。",
            },
            upload: "アップロード",
            legal: "リーガル",
            cropping: "クロッピング",
            tagging: "タグ付け",
            warning: "人工知能が生成した予測は低得点。ご確認ください。",
        },
        pictureCropModal: {
            head: "画像のリサイズ",
            width: "幅",
            height: "高さ",
            rotateLeft: "左へ回転",
            rotateRight: "右へ回転",
            success: "リサイズに成功しました",
        },
        pictureMoveModal: {
            title: "画像移動",
            artist: "アーティスト",
            success: "画像が移動されました",
        },
        multiRecordingSelect: {
            globalSearch: "全てのディスコグラフィー内を検索",
        },
        imageUploadModal: {
            head: "画像を追加する",
            link: "ここにリンクを追加",
            browse: "ブラウズ",
            file: "クリックしてコンピュータ上の画像を選択",
            success: "画像がアップロードされました",
            alert: {
                title: "よろしいですか？",
                text: "このウィンドウを閉じると、入力したデータは失われます。",
            },
            upload: "アップロード",
            legal: "リーガル",
            cropping: "クロッピング",
            tagging: "タグ付け",
            warning_upscale:
                "アップロードされた画像は、私たちの最小サイズ要件に合うようにアップスケールされます。数秒かかる場合があります。次のステップで画質を再チェックする。",
            warning_downscale:
                "アップロード時に画像サイズが縮小されます。数秒程度かかる場合があります。次のステップで画質を再確認してください。",
        },
        pictureInformationModal: {
            head: "画像情報",
            source: "出典",
            source_url: "URL 出典",
            author: "写真家",
            copyright: "著作権通知",
            year: "年",
            copyright_owner: "著作権所有者",
            license: "ライセンス",
            complementary: "補足",
            artist_site: "アーティストのウェブサイト",
            artist_label: "アーティストレーベル",
            other: "その他",
            description: {
                source: "画像をアップロードするウェブサイトの出典。",
                source_url: "出典ウェブサイトからの画像のURL。",
                author: "画像の著者。",
                copyright:
                    "当社の画像を使用するすべての人が表示する法的テキスト。",
                year: "画像が公開された年。",
                copyright_owner: "画像の財産権の所有者。写真と異なる場合のみ。",
                complementary:
                    "画像に関する追加情報、例えば画像が公開されている他のウェブサイトのURL。",
            },
            success: "画像情報が保存されました",
            alert: {
                title: "よろしいですか？",
                text: "このウィンドウを閉じると、入力したデータは失われます。",
            },
            upload: "アップロード",
            legal: "リーガル",
            cropping: "クロッピング",
            tagging: "タグ付け",
            licenseInformation: {
                head: "ライセンス情報",
                1: {
                    title: "GNU/FDL",
                    body: "GNU / FDL (無料ドキュメンテーションライセンス) に基づいて再発行された場合、誰でもドキュメントを変更、コピー、配布することができます。ソース (ドキュメントが見つかったページの URL) とライセンスの種類を表示します。このライセンスはもともと GNU/GPL ライセンスの下で配布されたフリー ソフトウェアのユーザー マニュアルを対象としており、変更の履歴、作成者の名前などとともにこのライセンスを含める必要が常にありました。時間が経つにつれて、このライセンスは他のドキュメント (画像、音声等) に拡張されてきましたが、常にライセンスのコピーを見つけるとは限りません。 (またこれは英語版でなければなりません。それがこの同じライセンスで指定されています。）ライセンスの種類、作成者、およびソースを追加してライセンスされた画像GNU/FDLを使用することに最善を尽くしています。",
                },
                2: {
                    title: "CC-BY",
                    body: "クリエイティブ・コモンズ: パタニティ (BY): 権利所有者は、商用目的を含む作品の利用、および配布を含む派生作品の作成を許可し、制限なしで許可されます。著者の名前を引用することにより、その著者に帰することができます。このライセンスは、作品の配布と最大限の使用に推奨されます。",
                },
                3: {
                    title: "CC-BY-SA",
                    body: "クリエイティブ・コモンズ: パタニティ + 同一条件での共有 (BY SA): ライセンスに基づいて配布される場合、権利所有者はオリジナル作品の使用 (商業目的を含む) および派生作品の作成を許可します。元の作品を管理するものと同じです。このライセンスはよくコピーレフトライセンスフリーソフトウェアと比較されます。これはウィキペディアが使用するライセンスです。",
                },
                4: {
                    title: "CC-BY-ND",
                    body: "クリエイティブ・コモンズ: 帰属 + 改変禁止 (BY ND): 権利所有者は、オリジナル作品の使用 (商業目的を含む) を許可しますが、二次的作品の作成は許可しません。",
                },
                5: {
                    title: "CC-BY-NC",
                    body: "クリエイティブ・コモンズ: 表示 + 商用利用禁止 (BY NC): 権利所有者は、商用利用でない限り、著作物の利用および派生物の作成を許可します。 (商用利用は引き続きその承認）",
                },
                6: {
                    title: "CC-BY-NC-SA",
                    body: "クリエイティブ・コモンズ: 表示 + 商用利用禁止 + 同じ条件での共有 (BY NC SA): 権利所有者は、商用以外の目的でのオリジナル作品の利用、および派生作品の作成を許可します。元の作品を管理するライセンスと同一のライセンスの下で配布されます。",
                },
                7: {
                    title: "CC-BY-NC-ND",
                    body: "クリエイティブ コモンズ: 表示 + 商用利用禁止 + 改変禁止 (BY NC ND) : 権利所有者は、非営利目的でのオリジナル作品の使用を許可しますが、派生作品の作成は許可しません。",
                },
                8: {
                    title: "無断複製・転用禁止",
                    body: "無断複製・転用禁止",
                },
                9: {
                    title: "ロイヤリティフリー",
                    body: "ロイヤリティフリー",
                },
                10: {
                    title: "Public Domain",
                    body: "パブリックドメインとは、著作権が存在しないことであり、与えられた作品が著作権やその他の法的制限を受けないことを意味する。",
                },
                11: {
                    title: "CC0",
                    body: "CC0 (別名: CC Zero) は、クリエイターが著作権を放棄し、作品を世界的なパブリックドメインにすることを可能にする、パブリック・ディビジョン・ツールです。CC0を利用することで、再利用者は無条件で、あらゆる媒体やフォーマットで配布、リミックス、改作、構築することができる。",
                },
            },
        },
        pictureInformationModalGetty: {
            upload: "アップロード",
            legal: "リーガル",
            cropping: "クロッピング",
            tagging: "タグ付け",
            source: "出典",
            source_url: "URL 出典",
            author: "写真家",
            copyright: "著作権通知",
            year: "年",
            copyright_owner: "著作権所有者",
            license: "ライセンス",
            complementary: "補足",
            description: {
                source: "画像をアップロードするウェブサイトの出典。",
                source_url: "出典ウェブサイトからの画像のURL。",
                author: "画像の著者。",
                copyright:
                    "当社の画像を使用するすべての人が表示する法的テキスト。",
                year: "画像が公開された年。",
                copyright_owner: "画像の財産権の所有者。写真と異なる場合のみ。",
                complementary:
                    "画像に関する追加情報、例えばコレクションの詳細や許可された使用。",
            },
        },
        imageCropModal: {
            head: "画像のリサイズ",
            width: "幅",
            height: "高さ",
            rotateLeft: "左へ回転",
            rotateRight: "右へ回転",
            success: "リサイズに成功しました",
            alert: {
                title: "よろしいですか？",
                text: "このウィンドウを閉じると、入力したデータは失われます。",
            },
            upload: "アップロード",
            legal: "リーガル",
            cropping: "クロッピング",
            tagging: "タグ付け",
        },
        labelUploadModal: {
            head: "アーティスト画像を追加",
            link: "ここにリンクを追加",
            browse: "ブラウズ",
            file: "クリックしてコンピュータ上の画像を選択",
            title: "タイトル",
            copyright: "著作権",
            takedown: "削除日",
            label: "レーベル",
            success: "画像がアップロードされました",
        },
        labelInformationModal: {
            head: "画像情報",
            title: "タイトル",
            copyright: "著作権",
            takedown: "削除日",
            success: "画像情報が保存されました",
        },
        charts: {
            categoryList: {
                empty: "結果なし",
                head: "カテゴリー",
                title: "タイトル",
                source: "参照",
                area: "エリア",
                country: "国",
                main: "メイン",
                format: "フォーマット",
                link: "リンク",
            },
            chartsList: {
                empty: "結果なし",
                head: "チャートリスト",
                year: "年",
                month: "月",
                startDate: "開始日",
                endDate: "終了日",
                dataMatched: "日付がマッチしました",
                matched: "マッチ済",
                translated: "翻訳済項目数",
                userData: "ToDo",
            },
            charts: {
                empty: "結果なし",
                head: "チャート",
                rank: "ランク",
                artist: "アーティスト",
                album: "アルバム",
                track: "トラック",
                previousRank: "以前のランク",
                dateIn: "イン日",
                dateOut: "アウト日",
                nbWeek: "週数",
                rankIn: "ランクイン",
                maxRank: "最高ランク",
                rankOut: "ランクアウト",
                msArtist: "MS アーティス",
                msAlbum: "MS アルバム",
                msRecordings: "MS レコーディング",
                informations: "情報",
                hasBioFR: "フランス語のバイオグラフィーがあります",
                hasBioEN: "英語のバイオグラフィーがあります",
                hasBioDE: "ドイツ語のバイオグラフィーがあります",
                hasBioJA: "日本語の伝記がある",
                hasBioES: "スペイン語のバイオグラフィーあり",
                hasBioPT: "ポルトガル語のバイオグラフィーがある",
                hasPictures: "画像があります",
                hasCuration: "キューレーションがあります",
                hasReview: "レビューがあります",
                lastUpdated: "最後のプランニング・エントリー",
                nbRelated: "関連数",
                albumsWithoutGenre: "ジャンル無しのアルバム",
                discarded: "破棄されました",
                matched: "マッチ済",
                translated: "翻訳済み",
                creation: "作成",
                filter: {
                    matched: {
                        placeholder: "マッチ済",
                        true: "マッチ済",
                        false: "マッチ無",
                    },
                    newEntry: {
                        placeholder: "新しいエントリー",
                        true: "新しいエントリー",
                        false: "古いエントリー",
                    },
                    toBeTreated: {
                        placeholder: "処理されるべきです",
                        true: "処理されるべきです",
                    },
                    discarded: {
                        placeholder: "破棄されました",
                        true: "破棄されました",
                        false: "破棄されませんでした",
                    },
                },
                albumTranslationsForm: {
                    artist: "アーティスト",
                    album: "アルバム",
                    translatedTitle: "翻訳タイトル",
                    translatedName: "翻訳名",
                    multipleNamesWarning:
                        "このアーティストは複数の翻訳名があります。以下の翻訳が削除されます",
                    save: {
                        success: "翻訳が保存されました",
                        error: (message) =>
                            `翻訳を保存する際にエラーが発生しました: ${message}`,
                        tooltip: "翻訳を保存する",
                    },
                    reset: "フォームをリセット",
                },
                trackTranslationsForm: {
                    artist: "アーティスト",
                    albums: "アルバム",
                    msRecordings: "レコーディング",
                    albumFormat: {
                        album: "アルバム",
                        ep: "ミニアルバム(EP)",
                        single: "シングル",
                        mixtape: "ミックステープ",
                        mcd: "マキシ",
                    },
                    albumType: {
                        live: "ライブ",
                        remix: "リミックス",
                        compilation: "コンピレーション",
                        karaoke: "カラオケ",
                        ring: "リング",
                        other: "その他",
                        original: "オリジナル",
                    },
                    translatedTitle: "翻訳タイトル",
                    translatedSubtitle: "翻訳済みサブタイトル",
                    translatedName: "翻訳名",
                    multipleNamesWarning:
                        "このアーティストは複数の翻訳名があります。以下の翻訳が削除されます",
                    multipleTitlesWarning:
                        "一致するすべての録音が同じ翻訳タイトルを持つわけではありません。マッチングが正しいかどうか確認してください。以下の翻訳が消去されます。",
                    noTitle: "タイトルなし",
                    noSubtitle: "サブタイトルなし",
                    noTranslation: "未訳",
                    save: {
                        success: "翻訳が保存されました",
                        error: (message) =>
                            `翻訳を保存する際にエラーが発生しました: ${message}`,
                        tooltip: "翻訳を保存する",
                    },
                    reset: "フォームをリセット",
                },
                discardButton: {
                    discardTooltip: "破棄",
                    recoverTooltip: "復元",
                    modalTitle: "コメント",
                },
                addToPlanningButton: {
                    title: "プランに追加",
                },
            },
        },
        picture: {
            action: {
                add: "画像を追加",
                edit: "画像情報を編集",
                tags: "元画像のタグを編集する",
                tagsVersion: "正方形の画像タグを編集する",
                crop: "画像を切り取る",
                move: "画像を移動",
                del: {
                    title: "画像を削除",
                    alert: {
                        title: "間違いないですか？",
                        text: "この画像を削除していいですか？",
                    },
                    success: "画像が削除されました",
                    error: (message) =>
                        `画像を削除中にエラーが発生しました: ${message}`,
                },
            },
            getty: {
                bas: {
                    head: "サンドボックス",
                    empty: "サンドボックスの中に画像がありません",
                    width: "幅",
                    height: "高さ",
                    created_at: "作成日",
                    loading: "ローディング...",
                    more: "さらに見る",
                    filters: {
                        search_type: {
                            title: "リサーチのタイプ",
                            best_match: "より良いマッチ",
                            most_popular: "一番人気",
                        },
                    },
                    action: {
                        detail: {
                            head: "サンドボックスの画像の情報",
                            dimensions: "寸法",
                            id: "ID",
                            title: "タイトル",
                            collection_name: "コレクション名",
                            caption: "キャプション",
                            people: "人",
                            date_camera_shot: "撮影日",
                            date_created: "作成日",
                            date_submitted: "登録日",
                            success: "画像が追加されました",
                            error: (message) =>
                                `画像を追加中にエラーが発生しました: ${message}`,
                        },
                    },
                },
            },
        },
    },
    utils: {
        select: {
            empty: "結果なし",
            placeholder: "検索...",
            loading: "ローディング...",
            noOptions: "オプションなし",
            create: "作成",
        },
        copy: {
            success: "テキストはクリップボードにコピーされました",
            error: "テキストはクリップボードにコピーできません",
        },
        pagination: {
            total: ({ size }) => `${size} 結果`,
            first_page: "最初のページ",
            last_page: "最後のページ",
            previous_page: "前のページ",
            next_page: "次のページ",
        },
        form: {
            required: "このフィールドは必須です",
            number: "このフィールドは数値ではありません。",
            date_placeholder: "年-月-日",
            date_format: "フォーマットが無効です (年-月-日)",
            date_zero: "日付 '0000-00-00' は無効です",
            barcode_format: "バーコードは 13 桁以下の数字でなければなりません",
            duration_format: "長さのフォーマットが無効です (時:分:秒)",
            duration_placeholder: "時:分:秒",
            year_format: "年のフォーマットが無効です (YYYY)",
            end_date_greater_than_start:
                "終了日は開始日以上でなければなりません。",
            url: "リンクが無効です",
            wysiwyg: {
                placeholder: "書き始める...",
                bold: "太字",
                italic: "斜体",
                underline: "下線",
                strikethrough: "取り消し線",
            },
            email: "メールが無効です",
            isni_format:
                "ISNI は 16 桁のコード、または 15 桁のコードの後に​​文字 X が続く必要があります",
            upload_max_size: `ファイルサイズが大きすぎます. (最大: ${
                UPLOAD_MAX_SIZE / 1000000
            } Mo)`,
            must_contain_uppercase: "少なくとも1つの大文字を含む必要があります",
            must_contain_lowercase: "少なくとも1つの小文字を含む必要があります",
            must_contain_8_characters: "8文字以上である必要があります",
            must_contain_digit: "少なくとも1つの数字を含む必要があります",
            must_contain_letter: "少なくとも1つの文字を含む必要があります",
            passwords_must_match: "パスワードが一致していません",
            not_hit_discovery_at_same_time:
                "ヒット」と「エディターズピック」は同時に入力できない。",
        },
        button: {
            cancel: "キャンセル",
            confirm: "確定",
            create: "作成",
            back: "戻る",
            add: "追加",
            edit: "編集",
            delete: "削除",
            deleteAll: "全てを削除",
            validate: "検証",
            previous: "前",
            next: "次",
            now: "今",
            save: "保存",
            close: "閉じる",
            transfer: "転送",
            reset: "リセット",
            approve: "認証",
            reject: "拒否",
            export: "エクスポート",
            generate: "作成",
            upload: "アップロード",
            search: "検索",
            merge: "統合",
            continue: "継続",
            download: "ダウンロード",
            browse: "ブラウズ",
            login: "ログイン",
            translate: "翻訳",
        },
        sweetalert: {
            warning: "警告",
            error: "エラー",
            cancel: "いいえ、キャンセルします",
            close: "閉じる",
            confirm: "はい、確認しました",
            ok: "はい、わかりました",
        },
        filters: {
            all: "全て",
            none: "無し",
        },
        lang: {
            PT: "ポルトガル語",
            JA: "日本語",
            DE: "ドイツ語",
            EN: "英語",
            ES: "スペイン語",
            FR: "フランス語",
            KO: "韓国語",
            CD_JOURNAL: "日本語",
        },
        nth: (n) => n?.toLocaleString("ja"),
    },
    landing: {
        breadcrumb: "ランディングページ",
        industry: "市場統計",
        ms: "データベース統計",
        industryStats: {
            chart_title: (top) => `公式トップ${top ?? ""}に基づく - `,
            chart_date: ({ start_date, end_date }) =>
                ` (${start_date ?? ""}から${end_date ?? ""}まで)`,
            top_title: (country) => `現在の人気タイトル - ${country ?? ""}`,
            top_title_description: "選択した国での当月の人気タイトル。",
            top_artist: (country) =>
                `過去12ヶ月で最も人気のあったアーティスト - ${country ?? ""}`,
            top_artist_description:
                "選択した国で今月最も人気のあるアーティスト。",
            top_progression: ({ id_country, country }) =>
                id_country == 33
                    ? `今日のベストエントリー - ${country ?? ""}`
                    : `今週のベストエントリー - ${country ?? ""}`,
            top_progression_description: (id_country) =>
                id_country == 33
                    ? "チャートのベスト・ニュー・エントリー"
                    : "チャートのベスト・ニュー・エントリー",
            sexe_repartition: (country) =>
                `アーティストのタイプ別内訳 - ${country ?? ""}`,
            sexe_repartition_description:
                "選択した国の公式チャートにおける「グループ」と「ソロ」アーティスト（ソロ・アーティストの場合は男女別）の割合。",
            genre_repartition: (country) =>
                `音楽ジャンル別分布 - ${country ?? ""}`,
            genre_repartition_description:
                "選択した国のチャートに表示されている音楽ジャンルの分布",
            language_repartition: (country) =>
                `歌われた言語 - ${country ?? ""}`,
            language_repartition_description:
                "選択した国の公式チャートで歌われている言語の分布。",
            top_emerging: (country) => `新進アーティスト - ${country ?? ""}`,
            top_emerging_description:
                "過去3ヶ月で初めてチャートに登場した最も人気のあるアーティスト。",
            current_catalog: (country) =>
                `年功序列のタイプ別楽曲分布 - ${country ?? ""}`,
            current_catalog_description:
                "選択した国の公式チャートにおける「現行」（発売日＜18ヶ月）および「ゴールド」（発売日＞18ヶ月）タイトルの代表",
            no_data: "不明",
            other: "その他",
            gold: "過去",
            current: "現在",
            person: "人",
            band: "バンド",
            man: "男性",
            woman: "女性",
            top_3: "トップ3:",
            total: "合計: ",
        },
        msStats: {
            artists: {
                title: "アーティスト",
                artists_with_photo: "画像を持つアーティスト",
                artists_with_bio: "略歴付きアーティスト",
                biographies_description:
                    "バイオグラフィーは以下の言語でご覧いただけます：",
            },
            albums: {
                title: "アルバム",
                canonical_albums: "正規アルバム",
                albums_with_cover_art: "カバーアートのあるアルバム",
                releases: "リリース",
            },
            recordings: {
                title: "レコーディング",
                unique_recording: "Unique Recording",
            },
        },
    },
};
