import React from "react";
import { ButtonGroup, Button, Overlay } from "react-bootstrap";
import moment from "moment";
import Widget from "shared/components/Widget";
import I18n from "shared/lib/I18n";
import Chart from "./Chart";
import ChartPopover from "./ChartPopover";
import styles from "./PopularityHistogram.module.css";
import clsx from "clsx";

const PopularityHistogram = ({
    current,
    gold,
    isLoading,
    popover,
    setPopover,
    showGold,
    setShowGold,
    popoverPointerRef,
    containerRef,
    location,
}) => {
    return (
        <Widget
            title="recording.view.popularityHistogram.head"
            isLoading={isLoading}
            headerCenter={
                showGold ? (
                    <strong>
                        <I18n t="recording.view.popularityHistogram.tabs.gold.header" />
                    </strong>
                ) : undefined
            }
            actions={
                <ButtonGroup>
                    <Button
                        onClick={() => setShowGold(false)}
                        active={!showGold}
                        title={I18n.getTranslation(
                            location,
                            "recording.view.popularityHistogram.tabs.current.description"
                        )}
                        className={clsx(
                            styles.button,
                            !showGold && styles.active
                        )}
                    >
                        <I18n t="recording.view.popularityHistogram.tabs.current.title" />
                    </Button>
                    <Button
                        onClick={() => setShowGold(true)}
                        active={showGold}
                        title={I18n.getTranslation(
                            location,
                            "recording.view.popularityHistogram.tabs.gold.description"
                        )}
                        className={clsx(
                            styles.button,
                            showGold && styles.active
                        )}
                    >
                        <I18n t="recording.view.popularityHistogram.tabs.gold.title" />
                    </Button>
                </ButtonGroup>
            }
        >
            <Widget.Body>
                <div ref={containerRef} style={{ height: "200px" }}>
                    <div
                        ref={popoverPointerRef}
                        style={{
                            width: "0",
                            height: "0",
                            position: "absolute",
                            left: `${popover.left}px`,
                            top: `${popover.top}px`,
                        }}
                    ></div>
                    <Overlay
                        show={popover.show}
                        target={popoverPointerRef.current}
                        placement="top"
                        flip={true}
                        popperConfig={{
                            modifiers: [
                                {
                                    name: "preventOverflow",
                                    options: {
                                        boundary: containerRef.current,
                                    },
                                },
                            ],
                        }}
                    >
                        <ChartPopover
                            date={popover.date}
                            dateFormat={showGold ? "YYYY" : "MMMM YYYY"}
                        />
                    </Overlay>
                    <Chart
                        dates={showGold ? gold : current}
                        dateUnit={showGold ? "year" : "month"}
                        dateFormat={showGold ? "YYYY" : "MMM YYYY"}
                        suggestedMin={
                            showGold
                                ? moment()
                                      .subtract(20, "year")
                                      .startOf("year")
                                      .format("YYYY-MM-DD")
                                : moment()
                                      .subtract(18, "month")
                                      .startOf("month")
                                      .format("YYYY-MM-DD")
                        }
                        suggestedMax={
                            showGold
                                ? moment()
                                      .subtract(1, "year")
                                      .startOf("year")
                                      .format("YYYY-MM-DD")
                                : moment()
                                      .subtract(1, "month")
                                      .startOf("month")
                                      .format("YYYY-MM-DD")
                        }
                        greySide={showGold ? "left" : "right"}
                        brightSideLabel={
                            showGold
                                ? I18n.getTranslation(
                                      location,
                                      "recording.view.popularityHistogram.gold"
                                  )
                                : ""
                        }
                        setPopover={setPopover}
                    />
                </div>
            </Widget.Body>
        </Widget>
    );
};

export default PopularityHistogram;
