import React, { useCallback, useEffect, useState } from "react";
import TopTitle from "./TopTitle";
import { OverlayTrigger, Popover } from "react-bootstrap";
import ImageLoader from "shared/components/ImageLoader";
import RecordingLink from "shared/components/RecordingLink";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import ArtistLink from "shared/components/ArtistLink";
import useAPI from "shared/hooks/useApi";
import { toast } from "react-toastify";
import placeholder from "assets/static/images/logo_placeholder.png";
import styles from "./TopTitle.module.css";
import clsx from "clsx";

const EnhancedTopTitle = ({ id_country, ...props }) => {
    const { api } = useAPI();
    const [state, setState] = useState({
        isLoading: false,
        titles: [],
    });

    const getTopTitle = useCallback(() => {
        setState((prev) => ({
            ...prev,
            isLoading: true,
        }));
        if (id_country) {
            api.get(`statistics/industry/${id_country}/top-title`)
                .then((response) => {
                    setState({
                        isLoading: false,
                        titles: response,
                    });
                })
                .catch((error) => {
                    console.error(error);
                    toast.error(error.message);
                    setState({
                        isLoading: false,
                        titles: [],
                    });
                });
        }
    }, [id_country]);

    useEffect(getTopTitle, [getTopTitle]);

    const columns = [
        {
            dataField: "cover",
            formatter: (cell) => (
                <span>
                    <ImageLoader
                        src={cell}
                        fallbackSrc={placeholder}
                        fallbackClassName={clsx(styles.placeholderImg)}
                        className="img"
                        width="30px"
                    />
                </span>
            ),
        },
        {
            dataField: "name",
            formatter: (cell, { id }) => (
                <RecordingLink recordingId={id} content={cell} />
            ),
        },
        {
            dataField: "artist",
            formatter: (cell, { id_artist }) => (
                <div style={{ display: "flex", alignItems: "center" }}>
                    <FontAwesomeIcon
                        icon={faUser}
                        fixedWidth
                        style={{ marginRight: "5px" }}
                    />
                    <ArtistLink artistId={id_artist} content={cell} />
                </div>
            ),
        },
    ];

    return (
        <TopTitle
            {...props}
            {...state}
            id_country={id_country}
            columns={columns}
        />
    );
};

export default EnhancedTopTitle;
